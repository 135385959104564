const hideRestForms = (current) => {
  const forms = document.querySelectorAll('[data-form-type]');
  if (forms.length > 0) {
    forms.forEach((element) => {
      if (element !== current) {
        element.classList.add('s-contact-form__form--hidden');
      }
    });
  }
};
const blockContact = () => {
  /**
   * Taby
   */

  const contactFormTabSources = document.querySelectorAll(
    '.contact-form-tab-source',
  );
  if (contactFormTabSources.length > 0) {
    contactFormTabSources.forEach((element) => {
      element.addEventListener('click', (e) => {
        const source = e.currentTarget.value;
        if (source) {
          const target = document.querySelector(`[data-form-type="${source}"]`);
          if (target) {
            target.classList.remove('s-contact-form__form--hidden');
            hideRestForms(target);
          }
        }
      });
    });
  }
};
export default blockContact;
