const validationFieldPhone = (input, field, count, errorText) => {
  if (input.value === '') {
    field.classList.add('cf-validation-required-fail');
    errorText.innerHTML = 'To pole jest wymagane';
    count++;
  } else if (input.value !== '' && !input.value.match(/^\d{9}$/)) {
    // } else if (input.value !== '' && !input.value.match(/^\+48\d{9}$/)) {
    field.classList.add('cf-validation-required-fail');
    errorText.innerHTML = 'Numer telefonu niepoprawny (9 cyfr)';
    // errorText.innerHTML = 'Numer telefonu niepoprawny (+48 000 000 000)';
    count++;
  } else {
    field.classList.remove('cf-validation-required-fail');
    errorText.innerHTML = '';
  }
  return count;
};
export default validationFieldPhone;
