import { Loader } from '@googlemaps/js-api-loader';
import mapSetMarkers from './map-set-markers';
import submitForm from './submit-form';
import searchMarkers from './map-search-markers';

const jquery = () => {
  // eslint-disable-next-line no-undef
  jQuery(() => {
    /**
     * Niestandardowe przyciski +/- przy ilości prodktu w koszyku
     */
    // eslint-disable-next-line no-undef
    jQuery(document).on(
      'click',
      '.c-shop-quantity__action--plus, .c-shop-quantity__action--minus',
      (e) => {
        // eslint-disable-next-line no-undef
        const qty = jQuery(e.currentTarget)
          .parent('.c-shop-quantity')
          .find('.c-shop-quantity__input');
        const val = parseFloat(qty.val());
        const max = parseFloat(qty.attr('max'));
        const min = parseFloat(qty.attr('min'));
        const step = parseFloat(qty.attr('step'));

        // eslint-disable-next-line no-undef
        if (jQuery(e.currentTarget).is('.c-shop-quantity__action--plus')) {
          if (max && max <= val) {
            qty.val(max).trigger('change');
          } else {
            qty.val(val + step).trigger('change');
          }
        } else if (min && min >= val) {
          qty.val(min).trigger('change');
        } else if (val > 1) {
          qty.val(val - step).trigger('change');
        }
      },
    );

    /**
     * Auto aktualizacja koszyka podczas klikania w +/-
     */
    let timeout;
    // eslint-disable-next-line no-undef
    jQuery('.woocommerce').on('change', 'input.c-shop-quantity__input', () => {
      if (timeout !== undefined) {
        clearTimeout(timeout);
      }
      timeout = setTimeout(() => {
        // eslint-disable-next-line no-undef
        jQuery("[name='update_cart']").trigger('click');
      }, 1000);
    });

    /**
     * Nowy kupon w koszyku
     */
    // eslint-disable-next-line no-undef
    jQuery('.woocommerce').on('input', '.new-coupon-input', (e) => {
      // eslint-disable-next-line no-undef
      jQuery('#coupon_code').val(e.currentTarget.value);
      console.log('asdsad');
    });

    // eslint-disable-next-line no-undef
    jQuery('.woocommerce').on('click', '.new-coupon-submit', () => {
      // eslint-disable-next-line no-undef
      jQuery('[name="apply_coupon"]').trigger('click');
      console.log('clicked');
    });

    /**
     * Wybór sklepu w metodach wysyłki
     */
    // eslint-disable-next-line no-undef
    jQuery(document).on('click', '.shipping-shop-choose', (e) => {
      e.preventDefault();
      // eslint-disable-next-line no-undef
      jQuery('[data-source="shipping-shop-choose"]').addClass('c-popup--show');

      const loader = new Loader({
        apiKey: 'AIzaSyDMmh-oEH0ZP5aujwvG4LPhs6n_vljrQ4E',
        version: 'weekly',
        libraries: ['places'],
      });

      loader.load().then(() => {
        // eslint-disable-next-line no-unused-vars
        const mapContainer = document.querySelector('.google-maps-shops');
        // eslint-disable-next-line no-undef
        const map = new google.maps.Map(mapContainer, {
          // eslint-disable-next-line no-undef
          center: new google.maps.LatLng(52.069679, 19.47858),
          zoom: 6,
          maxZoom: 14,
          // eslint-disable-next-line no-undef
          mapTypeId: google.maps.MapTypeId.ROADMAP,
        });

        /**
         * Wyszukiwanie
         */
        const form = document.querySelector('.c-shop-popup-shipping__form');
        if (form) {
          form.addEventListener('submit', (e) => {
            e.preventDefault();

            const formdata = new FormData(form);

            const ajax = new XMLHttpRequest();

            // eslint-disable-next-line no-undef
            ajax.open('POST', globalVariables.ajaxUrl, true);
            ajax.onreadystatechange = () => {
              if (ajax.readyState === 4 && ajax.status === 200) {
                if (ajax.responseText) {
                  const json = JSON.parse(ajax.responseText);
                  if (json.success) {
                    const { coords } = json.data;
                    if (coords.length > 0) {
                      searchMarkers(coords, map);
                    }
                  } else {
                    // eslint-disable-next-line no-undef
                    map.setCenter(new google.maps.LatLng(52.069679, 19.47858));
                    map.setZoom(6);
                  }
                }
              }
            };

            ajax.send(formdata);
            return false;
          });
        }

        mapSetMarkers(map, 'shipping');

        const shopsSearch = document.querySelector(
          '.c-shop-popup-shipping__map',
        );
        if (shopsSearch) {
          // eslint-disable-next-line no-undef
          const autocomplete = new google.maps.places.Autocomplete(
            document.getElementById('google-maps-shipping'),
          );

          // eslint-disable-next-line no-undef
          google.maps.event.addListener(autocomplete, 'place_changed', () => {
            const lat = document.querySelector('.map-lat');
            const lng = document.querySelector('.map-lng');

            const place = autocomplete.getPlace();
            const { location } = place.geometry;
            lat.value = location.lat();
            lng.value = location.lng();
            submitForm('.c-shop-popup-shipping__form');
          });
        }
      });
      jQuery('.akson-body').addClass('popap-open');
    });

    /**
     * Zmiana metody wysyłki
     */
    // eslint-disable-next-line no-undef
    jQuery(document).on('change', '.shipping_method', () => {
      // eslint-disable-next-line no-undef
      jQuery('#billing_shipping_shop').val('');
    });

    /**
     * NFZ
     */
    // eslint-disable-next-line no-undef
    jQuery(document).on('change', '#nfz-get-fee, #nfz-enable, #nfz-disable ', (e) => {
      const verifyContentNfz = document.getElementById('verification-apply-content');
      if(verifyContentNfz && e.target.id === 'nfz-enable' && e.target.checked){
        verifyContentNfz.classList.add('show');
      }else{
        verifyContentNfz.classList.remove('show');
      }
      // eslint-disable-next-line no-undef
      jQuery('body').trigger('update_checkout');
      // jQuery.ajax({
      //   url: globalVariables.ajaxUrl,
      //   type: 'post',
      //   data: {
      //     action: 'my_custom_shipping_table_update_action',
      //     nonce: globalVariables.ajaxNonce,
      //   },
      //   success(response) {
      //     console.log('response.data: ', response.data);
      //     if (response.success) {
      //       jQuery('#dostawa').html(response.data);
      //     }
      //   },
      // });
    });

    // my_custom_shipping_table_update
    // eslint-disable-next-line no-undef
    jQuery(document).on('change', '#billing_postcode', () => {
      jQuery('body').trigger('update_checkout');
      // jQuery.ajax({
      //   url: globalVariables.ajaxUrl,
      //   type: 'post',
      //   data: {
      //     action: 'my_custom_shipping_table_update_action',
      //     nonce: globalVariables.ajaxNonce,
      //   },
      //   success(response) {
      //     console.log('response.data: ', response.data);
      //     if (response.success) {
      //       jQuery('#dostawa').html(response.data);
      //     }
      //   },
      // });
    });

    /**
     * Aktualizacja pól po zmianie adresów
     */
    // eslint-disable-next-line no-undef
    jQuery(document).on('change', 'input[name="billing_address_book"]', () => {
      if (
        jQuery('#billing_address_book_field').hasClass(
          'wc-address-book-subscription-renewal',
        )
      ) {
        return;
      }
      const that = jQuery('#billing_address_book_field input:checked');
      const name = jQuery(that).val();
      console.log('name: ', name);
      if (name !== undefined && name !== null) {
        if (name === 'add_new') {
          // Clear values when adding a new address.
          jQuery('.woocommerce-billing-fields__field-wrapper input')
            .not(jQuery('#billing_country'))
            .not('#billing_address_book_add_new')
            .each(function () {
              const input = jQuery(this);
              if (
                input.attr('type') === 'checkbox' ||
                input.attr('type') === 'radio'
              ) {
                input.prop('checked', false);
              } else {
                input.val('');
              }
            });
          return;
        }

        if (name.length > 0) {
          // Show BlockUI overlay
          jQuery('.woocommerce-billing-fields').block();

          jQuery.ajax({
            url: woo_address_book.ajax_url,
            type: 'post',
            data: {
              action: 'wc_address_book_checkout_update',
              name,
              type: 'billing',
              nonce: woo_address_book.checkout_security,
            },
            dataType: 'json',
            success(response) {
              // Loop through all fields and set values to the form.
              Object.keys(response).forEach(function (key) {
                const input = jQuery(`#${key}`);
                if (input.length > 0) {
                  if (
                    woo_address_book.allow_readonly !== 'no' ||
                    input.attr('readonly') !== 'readonly'
                  ) {
                    if (input.is('select')) {
                      if (
                        input.hasClass('selectized') &&
                        input[0] &&
                        input[0].selectize
                      ) {
                        input[0].selectize.setValue(response[key]);
                      } else {
                        input.val(response[key]).trigger('change');
                      }
                    } else if (input.attr('type') === 'checkbox') {
                      input
                        .prop('checked', response[key] === '1')
                        .trigger('change');
                    } else {
                      input.val(response[key]).trigger('change');
                    }
                  }
                } else {
                  // Handle radio buttons.
                  const radio_field = jQuery(`#${key}_field`);
                  if (radio_field.length > 0) {
                    radio_field
                      .find("input[type='radio']")
                      .each(function (index, radio_button) {
                        if (jQuery(radio_button).val() === response[key]) {
                          jQuery(radio_button)
                            .prop('checked', true)
                            .trigger('change');
                        }
                      });
                  }
                }
              });

              // Remove BlockUI overlay
              jQuery('.woocommerce-billing-fields').unblock();
            },
          });
        }
      }
    });

    // eslint-disable-next-line no-undef
    jQuery(document.body).trigger('wc_fragment_refresh');

    /**
     * Aktualizacja countera w mini koszyku
     */
    jQuery('body').on('updated_cart_totals', function () {
      jQuery.ajax({
        url: globalVariables.ajaxUrl,
        type: 'post',
        data: {
          action: 'update_counter_action',
          nonce: globalVariables.ajaxNonce,
        },
        success(response) {
          if (response.success) {
            jQuery('.c-header-mini-cart__count').html(response.data.count);
            const messages = document.querySelectorAll('.woocommerce-message');
            if (messages.length > 0) {
              console.log('messages: ', messages);
              messages.forEach((message) => {
                setTimeout(() => {
                  message.remove();
                }, 7000);
              });
            }
          }
        },
      });

      // update minicart content
      jQuery.ajax({
        url: woocommerce_params.ajax_url,
        type: 'post',
        data: {
          action: 'ajax_update_mini_cart',
        },
        success(response) {
          jQuery('.widget_shopping_cart_content').html(response.data);
        },
      });
    });

    /**
     * Zamknięcie notyfikacji
     */
    jQuery('body').on(
      'click',
      '.woocommerce-message .close-notification',
      function () {
        jQuery(this)
          .closest('.woocommerce-message')
          .fadeOut(function () {
            jQuery(this).remove();
          });
      },
    );

    const detectLoginForm = document.querySelector('.l-shop-form-login');

    if (!detectLoginForm) {
      setTimeout(() => {
        const messages = document.querySelectorAll('.woocommerce-message');

        if (messages.length > 0) {
          messages.forEach((message) => {
            jQuery(message).fadeOut(function () {
              jQuery(this).remove();
            });
          });
        }
      }, 5000);
    }

    /**
     * Koszyk - popup usunięcia produktu
     */
    // eslint-disable-next-line no-undef
    jQuery('.woocommerce').on('click', '.basket-popup-trigger', (e) => {
      e.preventDefault();
      const findPopup = document.querySelector(
        `[data-source="${e.currentTarget.getAttribute('data-target')}"]`,
      );
      if (findPopup) {
        findPopup.classList.add('c-popup--show');
        jQuery('.akson-body').addClass('popap-open');
        const closes = findPopup.querySelectorAll('.popup-trigger-close');
        if (closes.length > 0) {
          closes.forEach((close) => {
            close.addEventListener('click', (closeP) => {
              closeP.preventDefault();
              findPopup.closest('.c-popup').classList.remove('c-popup--show');
              jQuery('.akson-body').removeClass('popap-open');
              // eslint-disable-next-line no-restricted-globals
              if (location.href.includes('?')) {
                // eslint-disable-next-line no-restricted-globals
                history.pushState({}, null, location.href.split('?')[0]);
              }
            });
          });
        }

        const overlay = findPopup.querySelector('.c-popup__overlay');
        if (overlay) {
          overlay.addEventListener('click', (closeO) => {
            closeO.preventDefault();
            findPopup.closest('.c-popup').classList.remove('c-popup--show');
            jQuery('.akson-body').removeClass('popap-open');
            // eslint-disable-next-line no-restricted-globals
            if (location.href.includes('?')) {
              // eslint-disable-next-line no-restricted-globals
              history.pushState({}, null, location.href.split('?')[0]);
            }
          });
        }
      }
    });

    /**
     * Blokada scroll'a po aktualizacji koszyka
     */
    jQuery(document).ajaxComplete(function () {
      if (
        // eslint-disable-next-line no-undef
        jQuery('body').hasClass('woocommerce-checkout') ||
        // eslint-disable-next-line no-undef
        jQuery('body').hasClass('woocommerce-cart')
      ) {
        // eslint-disable-next-line no-undef
        jQuery('html, body').stop();
      }
      if (jQuery('body').hasClass('woocommerce-cart')) {
        // eslint-disable-next-line no-undef
        if (jQuery('[role="alert"]').length > 0) {
          // eslint-disable-next-line no-undef
          jQuery('html, body').animate(
            {
              scrollTop:
                // eslint-disable-next-line no-undef
                jQuery('[role="alert"]').offset().top -
                // eslint-disable-next-line no-undef
                jQuery('.c-header').outerHeight() -
                20,
            },
            1000,
          );
        }
      }
    });

    jQuery('body').on('change', '#shipping_method input', () => {
      jQuery('body').trigger('update_checkout');
    });

    // Adresy na koncie klienta
    // eslint-disable-next-line no-undef
    if (jQuery('.woocommerce-Addresses')) {
      // eslint-disable-next-line no-undef
      jQuery(document).ajaxStart(function () {
        // eslint-disable-next-line no-undef
        jQuery('.l-shop-my-account__loader').addClass(
          'l-shop-my-account__loader--show',
        );
      });
      // eslint-disable-next-line no-undef
      jQuery(document).ajaxComplete(function () {
        // eslint-disable-next-line no-undef
        jQuery('.l-shop-my-account__loader').removeClass(
          'l-shop-my-account__loader--show',
        );
      });
      // eslint-disable-next-line no-undef
      jQuery('.wc-address-book-make-primary').on('click', function () {
        // eslint-disable-next-line no-undef
        jQuery('.wc-address-book-make-primary span').html(
          'Ustaw jako podstawowy',
        );
        // eslint-disable-next-line no-undef
        jQuery(this).find('span').html('Adres zmieniony!');
      });
    }
  });
};

export default jquery;
