import getCookie from './get-cookie';
import setCookie from './set-cookie';

const cPopup = () => {
  const popupTriggers = document.querySelectorAll('.popup-trigger');
  if (popupTriggers.length > 0) {
    popupTriggers.forEach((popupTrigger) => {
      popupTrigger.addEventListener('click', (e) => {
        e.preventDefault();
        const findPopup = document.querySelector(
          `[data-source="${e.currentTarget.getAttribute('data-target')}"]`,
        );
        if (findPopup) {
          findPopup.classList.add('c-popup--show');
          body.classList.add('popap-open');
        }
      });
    });
  }

  const popups = document.querySelectorAll('.c-popup');
  const body = document.querySelector('.akson-body');

  if (popups.length > 0) {
    popups.forEach((popup) => {
      const closes = popup.querySelectorAll('.popup-trigger-close');
      if (closes.length > 0) {
        closes.forEach((close) => {
          close.addEventListener('click', (e) => {
            e.preventDefault();
            const curentPopup = popup.closest('.c-popup');
            curentPopup.classList.remove('c-popup--show');
            if (
              curentPopup.getAttribute('data-source') === 'shop-checkout-login'
            ) {
              const guestInput = document.querySelector('#guest-user');
              const loginUser = document.querySelector('#login-user');
              const billingEmail = document.querySelector(
                '.c-shop-field--billing-email',
              );
              if (guestInput) guestInput.checked = true;
              if (loginUser) loginUser.checked = false;
              if (billingEmail) billingEmail.style.display = 'block';
            }
            body.classList.remove('popap-open');
            // eslint-disable-next-line no-restricted-globals
            if (location.href.includes('?')) {
              // eslint-disable-next-line no-restricted-globals
              history.pushState({}, null, location.href.split('?')[0]);
            }
          });
        });
      }
      const overlay = popup.querySelector('.c-popup__overlay');
      if (overlay) {
        overlay.addEventListener('click', (e) => {
          e.preventDefault();
          const curentPopup = popup.closest('.c-popup');
          curentPopup.classList.remove('c-popup--show');
          if (
            curentPopup.getAttribute('data-source') === 'shop-checkout-login'
          ) {
            const guestInput = document.querySelector('#guest-user');
            const loginUser = document.querySelector('#login-user');
            const billingEmail = document.querySelector(
              '.c-shop-field--billing-email',
            );
            if (guestInput) guestInput.checked = true;
            if (loginUser) loginUser.checked = false;
            if (billingEmail) billingEmail.style.display = 'block';
          }
          body.classList.remove('popap-open');
          // eslint-disable-next-line no-restricted-globals
          if (location.href.includes('?')) {
            // eslint-disable-next-line no-restricted-globals
            history.pushState({}, null, location.href.split('?')[0]);
          }
        });
      }
    });
  }

  const temporaryPopup = document.querySelector('.temporary-popup');
  if (temporaryPopup) {
    if (getCookie('temporary-popup') !== 'shown') {
      setTimeout(() => {
        temporaryPopup.classList.add('c-popup--show');
        body.classList.add('popap-open');
        setCookie('temporary-popup', 'shown', 7);
      }, 3000);
    }
  }
};

export default cPopup;
