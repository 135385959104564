// const scrollShowroomSidebar = () => {
//   const sidebarOnScroll = () => {
//     const cFooter = document.querySelector('.c-footer');
//     const cShowroomSidebar = document.querySelector('.c-showroom-sidebar');

//     if (cFooter && cShowroomSidebar) {
//       const windowHeight = window.innerHeight;

//       const offsetFooter = cFooter.offsetTop;

//       const breakpoint = offsetFooter - windowHeight;

//       const pageYOffset =
//         window.pageYOffset || document.documentElement.scrollTop;

//       if (pageYOffset > breakpoint) {
//         cShowroomSidebar.style.position = 'absolute';
//         cShowroomSidebar.style.bottom = `${cFooter.clientHeight}px`;
//       } else {
//         cShowroomSidebar.style.position = 'fixed';
//         cShowroomSidebar.style.bottom = 0;
//       }
//     }
//   };
//   sidebarOnScroll(); // standardowa inicjalizacja
//   window.addEventListener('scroll', sidebarOnScroll, { passive: true }); // wykrycie scrolla i ponowne liczenie wartości
//   window.addEventListener('resize', sidebarOnScroll, { passive: true }); // wykrycie resize i ponowne liczenie wartości
// };

const updateShowroomWidget = (responseJson) => {
  const showroomSidebarHtml = document.querySelector('.showroom-sidebar-html');

  if (showroomSidebarHtml && responseJson.data.showroomSidebarHtml) {
    showroomSidebarHtml.innerHTML = responseJson.data.showroomSidebarHtml;
  } else if (showroomSidebarHtml && !responseJson.data.showroomSidebarHtml) {
    showroomSidebarHtml.innerHTML = '';
  }
};
// const showroomSubscribeForm = () => {
//   const showRoomSubscribeForm = document.querySelector(
//     '.c-shop-showroom-subscribe-form',
//   );

//   if (showRoomSubscribeForm) {
//     const showRoomSubscribeFormThankYou = showRoomSubscribeForm.querySelector(
//       '.c-shop-showroom-subscribe-form__thank-you',
//     );
//     const showRoomSubscribeFormInputs = showRoomSubscribeForm.querySelector(
//       '.c-shop-showroom-subscribe-form__inputs',
//     );
//     showRoomSubscribeForm.addEventListener('submit', (e) => {
//       e.preventDefault();
//       const currentForm = e.currentTarget;
//       // eslint-disable-next-line no-unused-vars
//       const formdata = new FormData(currentForm);
//       const ajax = new XMLHttpRequest();
//       // eslint-disable-next-line no-undef
//       ajax.open('POST', globalVariables.ajaxUrl, true);
//       ajax.onreadystatechange = () => {
//         if (ajax.readyState === 4 && ajax.status === 200) {
//           if (ajax.responseText) {
//             const json = JSON.parse(ajax.responseText);
//             if (json.success) {
//               // TODO: inaczej to zrobić a nie urkywać header
//               document.querySelector('.l-shop-my-account__header').remove();
//               showRoomSubscribeFormInputs.remove();
//               showRoomSubscribeFormThankYou.classList.add(
//                 'c-shop-showroom-subscribe-form__thank-you--show',
//               );
//             }
//           }
//         }
//       };
//       ajax.send(formdata);
//     });
//   }
// };

const showRoomWidget = () => {
  const widgetToggle = document.querySelectorAll('.showroom-widget-toggle');
  if (widgetToggle.length > 0) {
    widgetToggle.forEach((toggle) => {
      toggle.addEventListener('click', (e) => {
        e.preventDefault();
        e.currentTarget
          .closest('.c-showroom-sidebar')
          .classList.toggle('c-showroom-sidebar--closed');
      });
    });
  }
};

const addToShowRoomClickEvent = (element) => {
  const updateEventsAfterChange = () => {
    const cShowroomSidebar = document.querySelector('.c-showroom-sidebar');
    if (cShowroomSidebar) {
      const cShowroomSidebarAdds =
        cShowroomSidebar.querySelectorAll('.add-to-showroom');
      if (cShowroomSidebarAdds.length > 0) {
        cShowroomSidebarAdds.forEach((add) => {
          addToShowRoomClickEvent(add);
        });
      }
    }
  };
  const removeActiveBtnStyle = (id) => {
    const showroomBtn = document.querySelector(`[data-id="${id}"]`);
    if (showroomBtn) {
      showroomBtn.classList.remove('c-shop-product-showroom--added');
    }
  };
  element.addEventListener('click', (e) => {
    e.preventDefault();

    const target = e.currentTarget;
    const targetText = target.querySelector('.c-shop-product-showroom__text');
    const type = target.getAttribute('data-type');
    const formdata = new FormData();
    formdata.append('action', target.getAttribute('data-action'));
    formdata.append('nonce', target.getAttribute('data-nonce'));
    formdata.append('id', target.getAttribute('data-id'));
    formdata.append('userId', target.getAttribute('data-user'));
    formdata.append('type', type);

    const cShowroomSidebar = document.querySelector('.c-showroom-sidebar');
    if (cShowroomSidebar) {
      if (cShowroomSidebar.classList.contains('c-showroom-sidebar--closed')) {
        formdata.append('is_sidebar_closed', true);
      }
    }

    const xhr = new XMLHttpRequest();

    xhr.onreadystatechange = () => {
      if (xhr.readyState === 4 && xhr.status === 200) {
        const responseJson = JSON.parse(xhr.responseText);
        if (responseJson.success) {
          if (responseJson.data.status === 'added') {
            if (type === 'product-form') {
              target.classList.add('c-shop-product-showroom--added');
              // TODO: dodać tłumaczenie
              if (targetText)
                targetText.innerHTML = 'Dodano do interaktywnej prezentacji';
              updateShowroomWidget(responseJson);
              showRoomWidget();
              updateEventsAfterChange();
            }
          } else if (responseJson.data.status === 'limit') {
            const popupShoproomLimit = document.querySelector(
              '.c-popup--showroom-limit',
            );
            if (popupShoproomLimit) {
              popupShoproomLimit.classList.add('c-popup--show');
              document.querySelector('.akson-body').classList.add('popap-open');
            }
          } else if (responseJson.data.status === 'deleted') {
            // eslint-disable-next-line no-lonely-if
            if (type === 'subscribe-form') {
              const form = document.querySelector(
                '.c-shop-showroom-subscribe-form',
              );
              if (form) {
                target
                  .closest('.c-shop-showroom-subscribe-form__item')
                  .remove();
                const countItems = form.querySelectorAll(
                  '.c-shop-showroom-subscribe-form__item',
                );
                if (countItems.length === 0) {
                  form.remove();
                }
              }
            } else if (type === 'product-form') {
              target.classList.remove('c-shop-product-showroom--added');
              // TODO: dodać tłumaczenie
              if (targetText)
                targetText.innerHTML = 'Dodaj do interaktywnej prezentacji';
              updateShowroomWidget(responseJson);
              showRoomWidget();
              updateEventsAfterChange();
            } else if (type === 'showroom-sidebar') {
              updateShowroomWidget(responseJson);
              showRoomWidget();
              updateEventsAfterChange();
              removeActiveBtnStyle(responseJson.data.removedItem);
            }
          }
        }
      }
    };
    // eslint-disable-next-line no-undef
    xhr.open('POST', globalVariables.ajaxUrl, true);
    xhr.send(formdata);
  });
};
const addToShowroom = () => {
  const addToShowRoom = document.querySelectorAll('.add-to-showroom');
  if (addToShowRoom.length > 0) {
    addToShowRoom.forEach((element) => {
      addToShowRoomClickEvent(element);
    });
  }
};

const removeShowroomEvent = () => {
  const removeShowRoomEvents = document.querySelectorAll(
    '.remove-showroom-event',
  );
  if (removeShowRoomEvents.length > 0) {
    removeShowRoomEvents.forEach((element) => {
      element.addEventListener('click', (e) => {
        e.preventDefault();
        const target = e.currentTarget;
        const formdata = new FormData();
        formdata.append('action', target.getAttribute('data-action'));
        formdata.append('nonce', target.getAttribute('data-nonce'));
        formdata.append('id', target.getAttribute('data-id'));
        formdata.append('userId', target.getAttribute('data-user'));

        const xhr = new XMLHttpRequest();

        xhr.onreadystatechange = () => {
          if (xhr.readyState === 4 && xhr.status === 200) {
            const responseJson = JSON.parse(xhr.responseText);
            if (responseJson.success) {
              if (responseJson.data.status === 'removed') {
                element
                  .closest('.c-shop-single-presenatation')
                  .classList.add('c-shop-single-presenatation--canceled');
              }
            }
          }
        };
        // eslint-disable-next-line no-undef
        xhr.open('POST', globalVariables.ajaxUrl, true);
        xhr.send(formdata);
      });
    });
  }
};

const shopShowroom = () => {
  addToShowroom();
  // showroomSubscribeForm();
  removeShowroomEvent();
  showRoomWidget();
  // scrollShowroomSidebar();

  /**
   * Dodanie do formularza logowania pola, które pozwala go zidentyfikować do przekierowania
   */
  const cShopCheckoutLogin = document.querySelector('.c-shop-checkout-login');
  if (cShopCheckoutLogin) {
    const form = cShopCheckoutLogin.querySelector('form');
    if (form) {
      const input = document.createElement('input');
      input.setAttribute('type', 'hidden');
      input.setAttribute('name', 'is_product');
      input.setAttribute('value', 'true');
      form.appendChild(input);
    }
  }

  /**
   * Akcja po zalogowaniu
   */
  const noLoggedTriggerActions = document.querySelectorAll(
    '.no-logged-trigger-action',
  );
  if (noLoggedTriggerActions.length > 0) {
    noLoggedTriggerActions.forEach((action) => {
      action.addEventListener('click', (e) => {
        const dataId = e.currentTarget.getAttribute('data-id');
        // eslint-disable-next-line no-undef
        const productId = globalVariables.product_id
          ? // eslint-disable-next-line no-undef
            globalVariables.product_id
          : dataId;
        const searchCShopCheckoutLogin = document.querySelector(
          '.c-shop-checkout-login',
        );

        if (searchCShopCheckoutLogin) {
          const form = searchCShopCheckoutLogin.querySelector('form');
          if (form) {
            const findExist = document.querySelector('[name="action_type"]');

            if (findExist) {
              findExist.remove();
            }

            const findRedirect = document.querySelector('[name="redirect"]');

            if (findRedirect) {
              findRedirect.remove();
            }

            // eslint-disable-next-line no-undef
            if (productId) {
              const productIdInput = document.createElement('input');
              productIdInput.setAttribute('type', 'hidden');
              productIdInput.setAttribute('name', 'product_id');
              // eslint-disable-next-line no-undef
              productIdInput.setAttribute('value', productId);
              form.appendChild(productIdInput);
            }

            const actionTypeInput = document.createElement('input');
            actionTypeInput.setAttribute('type', 'hidden');
            actionTypeInput.setAttribute('name', 'action_type');
            if (e.currentTarget.classList.contains('favorites-type')) {
              actionTypeInput.setAttribute('value', 'favorites_type');
            } else if (e.currentTarget.classList.contains('showroom-type')) {
              actionTypeInput.setAttribute('value', 'showroom_type');
            }
            form.appendChild(actionTypeInput);
          }
        }
      });
    });
  }
};
export default shopShowroom;
