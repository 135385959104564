import changeUrl from './change-url';

/**
 * Schowanie dropdown's po kliknięciu za ich obszar
 */
const catchClickOutside = () => {
  window.addEventListener('click', (e) => {
    const formCheckboxes = document.querySelectorAll(
      '.c-shop-filters__single--active',
    );
    formCheckboxes.forEach((el) => {
      if (!el.contains(e.target)) {
        el.classList.remove('c-shop-filters__single--active');
      }
    });
  });
};

/**
 * Wysłanie formularza w tle
 */
const submitFilters = () => {
  const form = document.querySelector('.c-shop-filters__form');
  const evt = new Event('submit', {
    bubbles: true,
    cancelable: false,
  });
  form.dispatchEvent(evt);
};

/**
 * Dodanie licznika do filtrów
 */
const countCheckboxes = () => {
  // eslint-disable-next-line prettier/prettier
  document.querySelectorAll('.c-shop-filters__single--tax').forEach((el1) => {
    let iterate = 0;
    el1.querySelectorAll('input').forEach((el2) => {
      if (el2.checked && !el2.classList.contains('mark-all')) {
        /* eslint-disable no-plusplus */
        iterate++;
      }
    });
    const counter = el1.querySelector('.c-shop-filters__counter');
    if (parseInt(iterate, 10) === 0) {
      counter.innerHTML = '';
    } else {
      counter.innerHTML = `(${iterate})`;
      const clearFiltersHandle = document.querySelector(
        '.clear-filters-handle',
      );
      if (clearFiltersHandle) {
        clearFiltersHandle.style.display = 'block';
      }
    }
  });
};

/**
 * Dodanie aktywnej ceny do filtra
 */
const countPrice = () => {
  document.querySelectorAll('.c-shop-filters__single--price').forEach((el1) => {
    const priceMin = el1.querySelector('.slider-input-min').value;
    const priceMax = el1.querySelector('.slider-input-max').value;
    const counter = el1.querySelector('.c-shop-filters__counter');
    if (parseInt(priceMin, 10) === 0 && parseInt(priceMax, 10) === 0) {
      counter.innerHTML = '';
    } else {
      counter.innerHTML = `(${priceMin} zł - ${priceMax} zł)`;
    }
  });
};
const clearPrice = () => {
  const range = document.querySelector(
    '.price-slider .c-price-slider__progress',
  );
  range.style.left = 0;
  range.style.right = '100%';
  document.querySelectorAll('.c-shop-filters__single--price').forEach((el1) => {
    const counter = el1.querySelector('.c-shop-filters__counter');
    counter.innerHTML = '';

    const priceInputs = el1.querySelectorAll('input[type="number"]');
    if (priceInputs.length > 0) {
      priceInputs.forEach((priceInput) => {
        // eslint-disable-next-line no-param-reassign
        priceInput.value = 0;
      });
    }

    const promotionCheckboxes = el1.querySelectorAll('input[type="checkbox"]');
    if (promotionCheckboxes.length > 0) {
      promotionCheckboxes.forEach((promotionInput) => {
        // eslint-disable-next-line no-param-reassign
        promotionInput.checked = false;
      });
    }
  });
};

/**
 * Wyczyszczenie filtrów
 */
const clearFilters = () => {
  document.querySelectorAll('.c-shop-filters__single--tax').forEach((el1) => {
    el1.querySelectorAll('input').forEach((el2) => {
      // eslint-disable-next-line no-param-reassign
      el2.checked = false;
    });
  });
  document.querySelectorAll('.c-shop-filters__single--price').forEach((el1) => {
    el1.querySelectorAll('input').forEach((el2) => {
      el2.setAttribute('value', 0);
    });
  });
  document.querySelectorAll('.c-shop-filters__single--sort').forEach((el1) => {
    el1.querySelectorAll('input').forEach((el2) => {
      // eslint-disable-next-line no-param-reassign
      el2.checked = false;
    });
  });
  clearPrice();
  submitFilters();
};

const handleClearFilters = () => {
  const clearFiltersHandle = document.querySelectorAll('.clear-filters-handle');
  clearFiltersHandle.forEach((element) => {
    element.addEventListener('click', (e) => {
      e.preventDefault();
      clearFilters();
    });
  });
};

let priceInputTimeOut = null;
let rangeInputTimeOut = null;

/**
 * Slider ceny (filtr)
 */
const priceSlider = () => {
  if (priceInputTimeOut) {
    clearTimeout(priceInputTimeOut);
  }
  if (rangeInputTimeOut) {
    clearTimeout(rangeInputTimeOut);
  }
  const rangeInput = document.querySelectorAll('.range-input input');
  const priceInput = document.querySelectorAll('.price-input input');
  const range = document.querySelector(
    '.price-slider .c-price-slider__progress',
  );
  const priceGap = 1;

  priceInput.forEach((input) => {
    input.addEventListener('change', (e) => {
      let minPrice = parseInt(priceInput[0].value, 10);
      let maxPrice = parseInt(priceInput[1].value, 10);

      if (!Number.isInteger(minPrice)) {
        minPrice = 0;
        priceInput[0].value = 0;
      }

      if (!Number.isInteger(maxPrice)) {
        maxPrice = 0;
        priceInput[1].value = 0;
      }

      if (minPrice <= maxPrice) {
        /* eslint-disable prettier/prettier */
        if (maxPrice - minPrice >= priceGap && maxPrice <= rangeInput[1].max) {
          if (e.target.className === 'slider-input-min') {
            rangeInput[0].value = minPrice;
            /* eslint-disable prefer-template */
            range.style.left = (minPrice / rangeInput[0].max) * 100 + '%';
          } else {
            rangeInput[1].value = maxPrice;
            /* eslint-disable prefer-template */
            range.style.right =
              100 - (maxPrice / rangeInput[1].max) * 100 + '%';
          }
        }
        priceInputTimeOut = setTimeout(() => {
          submitFilters();
          countPrice();
        }, 1000);
      }
    });
  });

  rangeInput.forEach((input) => {
    input.addEventListener('input', (e) => {
      const minVal = parseInt(rangeInput[0].value, 10);
      const maxVal = parseInt(rangeInput[1].value, 10);

      if (maxVal - minVal < priceGap) {
        if (e.target.className === 'slider-range-min') {
          rangeInput[0].value = maxVal - priceGap;
        } else {
          rangeInput[1].value = minVal + priceGap;
        }
      } else {
        priceInput[0].value = minVal;
        priceInput[1].value = maxVal;
        range.style.left = (minVal / rangeInput[0].max) * 100 + '%';
        range.style.right = 100 - (maxVal / rangeInput[1].max) * 100 + '%';
      }
    });
  });

  rangeInput.forEach((input) => {
    input.addEventListener('change', () => {
      rangeInputTimeOut = setTimeout(() => {
        submitFilters();
        countPrice();
      }, 1000);
    });
  });

  if (range && rangeInput) {
    const minVal = parseInt(rangeInput[0].value, 10);
    const maxVal = parseInt(rangeInput[1].value, 10);
    range.style.left = (minVal / rangeInput[0].max) * 100 + '%';
    range.style.right = 100 - (maxVal / rangeInput[1].max) * 100 + '%';
  }
};

const shopFilters = () => {
  const form = document.querySelector('.c-shop-filters__form');

  document.querySelectorAll('.c-shop-filters__header').forEach((element) => {
    element.addEventListener('click', (e) => {
      e.currentTarget
        .closest('.c-shop-filters__single')
        .classList.toggle('c-shop-filters__single--active');
      catchClickOutside();
    });
  });

  if (form) {
    form.addEventListener('submit', (e) => {
      e.preventDefault();
      const loader = document.querySelector('.s-shop-filters__loader');

      if (loader) {
        loader.classList.add('s-shop-filters__loader--show');
      }
      const formdata = new FormData(form);

      const ajax = new XMLHttpRequest();

      // eslint-disable-next-line no-undef
      ajax.open('POST', globalVariables.ajaxUrl, true);
      ajax.onreadystatechange = () => {
        const filtersResults = document.querySelector('.filters-results');
        const clearFiltersHandle = document.querySelector(
          '.clear-filters-handle',
        );
        const searchCountFilter = document.querySelector(
          '.js-found-product-count',
        );
        filtersResults.innerHTML = '';

        if (ajax.readyState === 4 && ajax.status === 200) {
          if (ajax.responseText) {
            const json = JSON.parse(ajax.responseText);
            if (json.success) {
              filtersResults.innerHTML = json.data.html && json.data.html;
              if (searchCountFilter && json.data.found_posts)
                searchCountFilter.textContent = json.data.found_posts;
              if (json.data.filters_active) {
                clearFiltersHandle.style = 'display: block;';
              } else {
                clearFiltersHandle.style = 'display: none;';
              }
            } else {
              filtersResults.innerHTML = json.data.html && json.data.html;
              if (
                searchCountFilter &&
                (json.data.found_posts === undefined ||
                  json.data.found_posts === null)
              )
                searchCountFilter.textContent = '0';
            }
            if (json.data.filters && json.data.post.base) {
              // eslint-disable-next-line no-underscore-dangle
              changeUrl(json.data.filters);
              // setPaginationSearchParams(json.data.filters);
            } else {
              changeUrl('');
            }
          }

          if (loader) {
            loader.classList.remove('s-shop-filters__loader--show');
          }

          countCheckboxes();
          handleClearFilters();
        }
      };

      ajax.send(formdata);
    });

    const formCheckboxes = form.querySelectorAll(
      '.c-shop-filters__input--checkbox:not(.mark-all)',
    );

    const formCheckboxesSorting = form.querySelectorAll(
      '.c-shop-filters__input--sorting',
    );

    if (formCheckboxesSorting.length > 0) {
      formCheckboxesSorting.forEach((el) => {
        el.addEventListener('change', (e) => {
          formCheckboxesSorting.forEach((checkbox) => {
            if (checkbox !== e.currentTarget) {
              // eslint-disable-next-line no-param-reassign
              checkbox.checked = false;
            }
          });
        });
      });
    }

    const radioBehaviourImitation = form.querySelectorAll(
      '.radio-behaviour-imitation',
    );

    if (radioBehaviourImitation.length > 0) {
      radioBehaviourImitation.forEach((el) => {
        const findInputs = el.querySelectorAll('input');
        if (findInputs.length > 0) {
          findInputs.forEach((input) => {
            input.addEventListener('change', (e) => {
              findInputs.forEach((checkbox) => {
                if (checkbox !== e.currentTarget) {
                  // eslint-disable-next-line no-param-reassign
                  checkbox.checked = false;
                }
              });
            });
          });
        }
      });
    }

    formCheckboxes.forEach((el) => {
      el.addEventListener('change', () => {
        submitFilters();
      });
    });

    const markAll = document.querySelectorAll('.mark-all');

    if (markAll.length > 0) {
      markAll.forEach((element1) => {
        element1.addEventListener('change', (e) => {
          const closestContainer = e.currentTarget.closest(
            '.c-shop-filters__content',
          );
          if (closestContainer) {
            const inputs = closestContainer.querySelectorAll('input');
            if (inputs.length > 0) {
              inputs.forEach((element2) => {
                if (e.currentTarget.checked) {
                  // eslint-disable-next-line no-param-reassign
                  element2.checked = true;
                } else {
                  // eslint-disable-next-line no-param-reassign
                  element2.checked = false;
                }
              });
              inputs[1].dispatchEvent(new Event('change'));
            }
          }
        });
      });
    }

    handleClearFilters();
    priceSlider();
    countCheckboxes();
    countPrice();
  }
};

export default shopFilters;
