/* plain JS slideToggle https://github.com/ericbutler555/plain-js-slidetoggle */

// eslint-disable-next-line no-underscore-dangle
function _s(el, duration = 400, callback, isDown = false) {
  const a = el;
  a.style.overflow = 'hidden';
  if (isDown) a.style.display = 'block';

  const elStyles = window.getComputedStyle(a);

  const elHeight = parseFloat(elStyles.getPropertyValue('height'));
  const elPaddingTop = parseFloat(elStyles.getPropertyValue('padding-top'));
  const elPaddingBottom = parseFloat(
    elStyles.getPropertyValue('padding-bottom'),
  );
  const elMarginTop = parseFloat(elStyles.getPropertyValue('margin-top'));
  const elMarginBottom = parseFloat(elStyles.getPropertyValue('margin-bottom'));

  const stepHeight = elHeight / duration;
  const stepPaddingTop = elPaddingTop / duration;
  const stepPaddingBottom = elPaddingBottom / duration;
  const stepMarginTop = elMarginTop / duration;
  const stepMarginBottom = elMarginBottom / duration;

  let start;

  function step(timestamp) {
    if (start === undefined) start = timestamp;

    const elapsed = timestamp - start;

    if (isDown) {
      a.style.height = `${stepHeight * elapsed}px`;
      a.style.paddingTop = `${stepPaddingTop * elapsed}px`;
      a.style.paddingBottom = `${stepPaddingBottom * elapsed}px`;
      a.style.marginTop = `${stepMarginTop * elapsed}px`;
      a.style.marginBottom = `${stepMarginBottom * elapsed}px`;
    } else {
      a.style.height = `${elHeight - stepHeight * elapsed}px`;
      a.style.paddingTop = `${elPaddingTop - stepPaddingTop * elapsed}px`;
      a.style.paddingBottom = `${
        elPaddingBottom - stepPaddingBottom * elapsed
      }px`;
      a.style.marginTop = `${elMarginTop - stepMarginTop * elapsed}px`;
      a.style.marginBottom = `${elMarginBottom - stepMarginBottom * elapsed}px`;
    }

    if (elapsed >= duration) {
      a.style.height = '';
      a.style.paddingTop = '';
      a.style.paddingBottom = '';
      a.style.marginTop = '';
      a.style.marginBottom = '';
      a.style.overflow = '';
      if (!isDown) a.style.display = 'none';
      if (typeof callback === 'function') callback();
    } else {
      window.requestAnimationFrame(step);
    }
  }

  window.requestAnimationFrame(step);
}

function slideToggle(duration, callback) {
  if (this.clientHeight === 0) {
    _s(this, duration, callback, true);
  } else {
    _s(this, duration, callback);
  }
}

function slideUp(duration, callback) {
  _s(this, duration, callback);
}

function slideDown(duration, callback) {
  _s(this, duration, callback, true);
}

HTMLElement.prototype.slideToggle = slideToggle;

HTMLElement.prototype.slideUp = slideUp;

HTMLElement.prototype.slideDown = slideDown;
