/* eslint-disable no-unused-vars */
import { Loader } from '@googlemaps/js-api-loader';
import detectScrollVisible from './detect-scoll-visible';
import searchMarkers from './map-search-markers';
import mapSetMarkers from './map-set-markers';
import submitForm from './submit-form';

const mapHeight = () => {
  const googleMapsShops = document.querySelector('.google-maps-shops');
  if (googleMapsShops) {
    googleMapsShops.style.height = `${googleMapsShops.clientHeight}px`;
  }
};

const blockShopsSearch = () => {
  let mapInitiated = false;
  const section = document.querySelector('.s-shops-search');
  if (section) {
    const loader = new Loader({
      apiKey: 'AIzaSyDMmh-oEH0ZP5aujwvG4LPhs6n_vljrQ4E',
      version: 'weekly',
      libraries: ['places'],
    });

    const wrapper = document.querySelector('.google-maps-shops-test');

    document.addEventListener(
      'scroll',
      () => {
        console.log('visible: ', detectScrollVisible(section));
        if (detectScrollVisible(section) && !mapInitiated) {
          mapInitiated = true;
          wrapper.remove();

          loader.load().then(() => {
            // eslint-disable-next-line no-unused-vars
            const mapContainer = document.querySelector('.google-maps-shops');
            // eslint-disable-next-line no-undef
            const map = new google.maps.Map(mapContainer, {
              // eslint-disable-next-line no-undef
              center: new google.maps.LatLng(52.069679, 19.47858),
              zoom: 6,
              maxZoom: 14,
              // eslint-disable-next-line no-undef
              mapTypeId: google.maps.MapTypeId.ROADMAP,
            });

            /**
             * Autocomplete
             */
            const shopsSearch = document.querySelector('#shops-search');
            if (shopsSearch) {
              const options = {
                componentRestrictions: { country: ['pl'] },
              };

              // eslint-disable-next-line no-undef
              const autocomplete = new google.maps.places.Autocomplete(
                shopsSearch,
                options,
              );

              // eslint-disable-next-line no-undef
              google.maps.event.addListener(
                autocomplete,
                'place_changed',
                () => {
                  const lat = document.querySelector('.map-lat');
                  const lng = document.querySelector('.map-lng');
                  const voivodeship =
                    document.querySelector('.map-voivodeship');

                  const place = autocomplete.getPlace();

                  const filteredArray = place.address_components.filter(
                    (addressComponent) => {
                      return addressComponent.types.includes(
                        'administrative_area_level_1',
                      );
                    },
                  );
                  const filteredVoivodeship = filteredArray.length
                    ? filteredArray[0].long_name
                    : '';

                  const { location } = place.geometry;
                  lat.value = location.lat();
                  lng.value = location.lng();

                  if (voivodeship && filteredVoivodeship) {
                    voivodeship.value = filteredVoivodeship;
                  }

                  submitForm('.s-shops-search__form');
                },
              );
            }

            mapHeight();
            window.addEventListener('resize', mapHeight);

            /**
             * Wyszukiwanie
             */
            const form = document.querySelector('.s-shops-search__form');

            const sShopsSearchInside = document.querySelector(
              '.s-shops-search__inside',
            );
            const sShopsSearchSpecialists = document.querySelector(
              '.s-shops-search__specialists',
            );
            const sShopsSearchSpecialistsMobile = document.querySelector(
              '.s-shops-search__specialists-mobile',
            );

            if (form) {
              const mapMarkers = mapSetMarkers(map);

              form.addEventListener('submit', (e) => {
                e.preventDefault();

                const formdata = new FormData(form);

                const ajax = new XMLHttpRequest();

                // eslint-disable-next-line no-undef
                ajax.open('POST', globalVariables.ajaxUrl, true);
                ajax.onreadystatechange = () => {
                  if (ajax.readyState === 4 && ajax.status === 200) {
                    if (ajax.responseText) {
                      const json = JSON.parse(ajax.responseText);
                      if (json.success) {
                        const { coords, voivodeshipsHtml } = json.data;
                        if (coords.length > 0) {
                          searchMarkers(coords, map, mapMarkers);
                        } else {
                          map.setCenter(
                            // eslint-disable-next-line no-undef
                            new google.maps.LatLng(52.069679, 19.47858),
                          );
                          map.setZoom(6);
                        }
                        if (voivodeshipsHtml) {
                          sShopsSearchInside.style.display = 'none';
                          if (window.matchMedia('(max-width: 768px)').matches) {
                            sShopsSearchSpecialistsMobile.innerHTML =
                              voivodeshipsHtml;
                            sShopsSearchSpecialistsMobile.classList.add(
                              's-shops-search__specialists-mobile--visible',
                            );
                          } else {
                            sShopsSearchSpecialists.innerHTML =
                              voivodeshipsHtml;
                          }
                        } else {
                          sShopsSearchInside.style.display = 'block';
                          if (window.matchMedia('(max-width: 768px)').matches) {
                            sShopsSearchSpecialistsMobile.innerHTML = '';
                            sShopsSearchSpecialistsMobile.classList.remove(
                              's-shops-search__specialists-mobile--visible',
                            );
                          } else {
                            sShopsSearchSpecialists.innerHTML = '';
                          }
                        }
                      } else {
                        // eslint-disable-next-line no-undef
                        map.setCenter(
                          // eslint-disable-next-line no-undef
                          new google.maps.LatLng(52.069679, 19.47858),
                        );
                        map.setZoom(6);
                      }
                    }
                  }
                };

                ajax.send(formdata);
                return false;
              });
            }
          });
        }
      },
      { passive: true },
    );
  }
};
export default blockShopsSearch;
