const catchClickOutside = () => {
  window.addEventListener('click', (e) => {
    const formCheckboxes = document.querySelectorAll(
      '.c-header-mini-cart--active',
    );
    formCheckboxes.forEach((el) => {
      if (!el.contains(e.target)) {
        el.classList.remove('c-header-mini-cart--active');
      }
    });
  });
};

const headerMiniCart = () => {
  catchClickOutside();
  const headerCartBtn = document.querySelector('.header-cart-btn');
  const headerCartClose = document.querySelector('.header-cart-close');

  const cHeaderMiniCart = document.querySelector('.c-header-mini-cart');
  if (headerCartBtn || cHeaderMiniCart) {
    headerCartBtn.addEventListener('click', () => {
      cHeaderMiniCart.classList.toggle('c-header-mini-cart--active');
    });
    headerCartClose.addEventListener('click', () => {
      cHeaderMiniCart.classList.remove('c-header-mini-cart--active');
    });
  }
};

export default headerMiniCart;
