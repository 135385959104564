const blockFaq = () => {
  const accordions = document.querySelectorAll('.accordion');
  accordions.forEach((accordion) => {
    accordion.addEventListener('click', (e) => {
      if (e.target.classList.contains('accordion__tab')) {
        const accordionTab = e.target;
        const item = accordionTab.closest('.accordion__list-item');

        if (accordionTab.classList.contains('accordion__tab--is-open')) {
          accordionTab.classList.remove('accordion__tab--is-open');
        } else {
          accordionTab.classList.add('accordion__tab--is-open');
        }

        if (item.classList.contains('accordion__list-item--is-open')) {
          item.classList.remove('accordion__list-item--is-open');
        } else {
          item.classList.add('accordion__list-item--is-open');
        }
      }
    });
  });
};
export default blockFaq;
