import smoothScroll from './smooth-scroll';
import validationFieldPassword from './validation-field-password';
import validationFields from './validation-fields';

const validationFormWoo = () => {
  /**
   * Walidacja hasła live
   */
  const requiredFieldsPassword = document.querySelectorAll(
    '.cf-validation-required[data-type="password"]:not(.check-password-repeat)',
  );
  if (requiredFieldsPassword.length > 0) {
    requiredFieldsPassword.forEach((field) => {
      const input = field.querySelector('.cf-validation-input');
      const errorText = field.querySelector('.cf-validation-error');
      if (input) {
        input.addEventListener('input', (e) => {
          validationFieldPassword(e.currentTarget, field, 0, errorText);
        });
      }
    });
  }

  /**
   * Walidacja formularza
   */
  const registrationForm = document.querySelector('.validate-form-woo');
  if (registrationForm) {
    registrationForm.addEventListener('submit', (e) => {
      const countErrors = validationFields(e.currentTarget);

      const firstValidationField = document.querySelector(
        '.cf-validation-required-fail',
      );

      if (countErrors === 0) {
        e.currentTarget.submit();
      } else {
        if (firstValidationField) {
          smoothScroll(firstValidationField.offsetTop, 500);
        }
        e.preventDefault();
      }
    });
  }
};

export default validationFormWoo;
