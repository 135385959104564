const submitForm = (formClass) => {
  const form = document.querySelector(formClass);
  const evt = new Event('submit', {
    bubbles: true,
    cancelable: false,
  });
  form.dispatchEvent(evt);
};

export default submitForm;
