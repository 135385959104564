const catchClickOutside = () => {
  window.addEventListener('click', (e) => {
    const dropdownLists = document.querySelectorAll('.c-dropdown');
    if (dropdownLists.length > 0) {
      dropdownLists.forEach((el) => {
        if (!el.contains(e.target)) {
          el.classList.remove('c-dropdown--active');
        }
      });
    }
  });
};

const dropdown = () => {
  const dropdownInit = () => {
    // if (window.innerWidth <= 768) {
    const dropdowns = document.querySelectorAll('.c-dropdown');
    if (dropdowns.length > 0) {
      dropdowns.forEach((single) => {
        const getFirstElement = single.querySelector(
          '.c-dropdown__item.is-active',
        );
        const label = single.querySelector('.c-dropdown__label');
        if (getFirstElement && label) {
          getFirstElement.remove();
          label.querySelector('span').innerHTML =
            getFirstElement.querySelector('a').innerHTML;
          label.addEventListener('click', () => {
            single.classList.toggle('c-dropdown--active');
          });
        }
      });
    }
    // }
  };
  dropdownInit();
  window.addEventListener('resize', dropdownInit);
  catchClickOutside();
};
export default dropdown;
