const showSecondaryMenu = (primaryLevel) => {
  const primaryLevelId = primaryLevel.getAttribute('data-id');
  const primaryLevelName = primaryLevel.getAttribute('data-name');
  const backButton = document.querySelector('.c-header-filters-menu__back');
  const primaryLevelItems = document.querySelectorAll('.filters-primary-level');
  primaryLevelItems.forEach((el) => {
    if (el.getAttribute('data-id') !== primaryLevelId) {
      el.classList.add('filters-primary-level--hidden');
    }
  });

  backButton.classList.remove('c-header-filters-menu__back--hidden');
  backButton.querySelector('span').innerHTML = primaryLevelName;
};

const backFirstLevel = () => {
  const back = document.querySelector('.c-header-filters-menu__back');
  const primaryLevelItems = document.querySelectorAll('.filters-primary-level');
  const primaryBtns = document.querySelectorAll('.header-filters-primary-btn');

  primaryBtns.forEach((el) => {
    el.closest('.c-header-filters-menu__level').classList.remove(
      'c-header-filters-menu__level--hidden',
    );
  });

  primaryLevelItems.forEach((el) => {
    el.classList.remove('filters-primary-level--hidden');
    el.classList.remove('active-secondary');
  });

  back.classList.add('c-header-filters-menu__back--hidden');
};

const headerFiltersMenu = (aksonBody) => {
  const closeHandler = document.querySelectorAll('.handle-filters-close');

  if (closeHandler.length > 0) {
    closeHandler.forEach((element) => {
      element.addEventListener('click', (e) => {
        e.preventDefault();
        aksonBody.classList.remove('show-mobile-filters');
        backFirstLevel();
      });
    });
  }

  const mobileMenu = document.querySelector('.header-filters-menu');
  const back = document.querySelector('.c-header-filters-menu__back');

  if (mobileMenu) {
    const primaryBtns = mobileMenu.querySelectorAll(
      '.header-filters-primary-btn',
    );
    primaryBtns.forEach((el) => {
      el.addEventListener('click', (e) => {
        e.preventDefault();
        e.currentTarget
          .closest('.c-header-filters-menu__level')
          .classList.add('c-header-filters-menu__level--hidden');
        showSecondaryMenu(e.currentTarget.closest('.filters-primary-level'));
        e.currentTarget
          .closest('.c-header-filters-menu__item--primary')
          .classList.add('active-secondary');
      });
    });
  }

  if (back) {
    back.addEventListener('click', () => {
      backFirstLevel();
    });
  }
};

export default headerFiltersMenu;
