const triggerConfiguration = (trigger, e) => {
  let myTimeout;
  const configuratorSidebar = document.querySelector('.c-configurator-sidebar');
  const productPrice = document.querySelector(
    '.c-shop-simple-cart-loop__action',
  );
  configuratorSidebar.classList.add('c-configurator-sidebar--show-overlay');

  const submitValue = e ? e.currentTarget.value : null;
  clearTimeout(myTimeout);
  myTimeout = setTimeout(() => {
    const formdata = new FormData(trigger.closest('form'));
    formdata.append('submit-value', submitValue);

    const ajax = new XMLHttpRequest();
    // eslint-disable-next-line no-undef
    ajax.open('POST', globalVariables.ajaxUrl, true);
    ajax.onreadystatechange = () => {
      if (ajax.readyState === 4 && ajax.status === 200) {
        if (ajax.responseText) {
          const json = JSON.parse(ajax.responseText);
          if (json.success) {
            configuratorSidebar.classList.remove(
              'c-configurator-sidebar--show-overlay',
            );
            if (json.data.redirect) {
              window.location.href = json.data.redirect;
            }
            if (json.data.new_price) {
              productPrice.innerHTML = json.data.new_price;
            }
          } else {
            console.log('json error: ', json);
          }
        }
      }
    };

    ajax.send(formdata);
  }, 500);
};

const configurator = () => {
  const configuratorForm = document.querySelector('.configurator-form');

  if (configuratorForm) {
    const triggerConfiguratorBtns = document.querySelectorAll(
      '.trigger-configurator-btn',
    );
    if (triggerConfiguratorBtns.length > 0) {
      triggerConfiguration(configuratorForm);
      triggerConfiguratorBtns.forEach((trigger) => {
        trigger.addEventListener('click', (e) => {
          e.preventDefault();
          triggerConfiguration(trigger, e);
        });
      });
    }

    const triggerConfiguratorCheckbox = document.querySelectorAll(
      '.trigger-configuration-checkbox',
    );
    if (triggerConfiguratorCheckbox.length > 0) {
      triggerConfiguratorCheckbox.forEach((trigger) => {
        trigger.addEventListener('change', (e) => {
          e.preventDefault();
          triggerConfiguration(trigger, e);
        });
      });
    }
  }

  /**
   * Usuwanie kafelków w koncie uzytkownika
   */

  let configurationTimeOut;
  const removeConfiguration = document.querySelectorAll(
    '.c-remove-configuration',
  );

  if (removeConfiguration) {
    removeConfiguration.forEach((remove) => {
      remove.addEventListener('click', (e) => {
        e.preventDefault();
        const currentBtn = e.currentTarget;
        clearTimeout(configurationTimeOut);
        configurationTimeOut = setTimeout(() => {
          const formdata = new FormData(currentBtn.closest('form'));

          const ajax = new XMLHttpRequest();
          // eslint-disable-next-line no-undef
          ajax.open('POST', globalVariables.ajaxUrl, true);
          ajax.onreadystatechange = () => {
            if (ajax.readyState === 4 && ajax.status === 200) {
              if (ajax.responseText) {
                const json = JSON.parse(ajax.responseText);
                if (json.success) {
                  if (json.data.status === 'removed') {
                    const closesetItem = currentBtn.closest(
                      '.c-shop-account-order-item',
                    );
                    closesetItem.remove();

                    const closestBody = document.querySelector(
                      '.l-shop-my-account__body',
                    );
                    if (closestBody) {
                      const countItms = closestBody.querySelectorAll(
                        '.c-shop-account-order-item',
                      );
                      const overlay = closestBody.querySelector(
                        '.l-shop-my-account__empty',
                      );
                      if (countItms.length === 0) {
                        overlay.classList.remove(
                          'l-shop-my-account__empty--hidden',
                        );
                      }
                    }
                  }
                } else {
                  console.log('json error: ', json);
                }
              }
            }
          };

          ajax.send(formdata);
        }, 500);
      });
    });
  }
  const triggerPanelConfiguratorBtn = document.querySelector(
    '.trigger-panel-configurator-btn',
  );

  if (triggerPanelConfiguratorBtn) {
    triggerPanelConfiguratorBtn.addEventListener('click', (e) => {
      e.preventDefault();

      const current = e.currentTarget;
      const productId = current.getAttribute('data-product-id');
      const userId = current.getAttribute('data-user-id');
      const nonce = current.getAttribute('data-nonce');

      const data = new FormData();
      data.append('submit-value', 'save');
      data.append('cookie_type', true);
      data.append('product_id', productId);
      data.append('user_id', userId);
      data.append('nonce', nonce);
      data.append('action', 'configurator_form_action');

      current.innerHTML = 'Zapisuję ...'; // TODO: tłumaczenie

      const xhr = new XMLHttpRequest();
      xhr.onreadystatechange = () => {
        if (xhr.readyState === 4 && xhr.status === 200) {
          const responseJson = JSON.parse(xhr.responseText);
          if (responseJson.success) {
            if (responseJson.data.redirect) {
              window.location.href = responseJson.data.redirect;
            }
          }
        }
      };

      /* global globalVariables */
      /* eslint no-undef: "error" */
      xhr.open('POST', globalVariables.ajaxUrl, true);
      xhr.send(data);
    });
  }
};

export default configurator;
