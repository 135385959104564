const blockPresentation = () => {
  const presentations = document.querySelectorAll('.s-presentation');

  if (presentations) {
    presentations.forEach((el) => {
      const playBtn = el.querySelector('.movie-play');
      const movie = el.querySelector('.movie');
      if (playBtn) {
        playBtn.addEventListener('click', () => {
          el.classList.add('movie-played');
          if (movie) {
            movie.play();
          }
        });
      }
      if (movie) {
        movie.addEventListener('click', (e) => {
          if (e.currentTarget.paused) {
            el.classList.remove('movie-played');
          }
        });
      }
    });
  }
};

export default blockPresentation;
