import { EffectFade } from 'swiper';

const blockHero = (Swiper, Navigation, Pagination, Autoplay) => {
  const hero = document.querySelector('.s-hero');

  if (hero) {
    const pagination = hero.querySelector('.s-hero__pagination-dots');
    const arrowPrev = hero.querySelector('.swiper-button-prev');
    const arrowNext = hero.querySelector('.swiper-button-next');
    const heroSlider = hero.querySelector('.hero-slider');

    const swiper = new Swiper(heroSlider, {
      direction: 'horizontal',
      modules: [Navigation, Pagination, Autoplay, EffectFade],
      loop: true,
      slidesPerView: 1,
      effect: 'fade',
      speed: 1000,
      fadeEffect: {
        crossFade: true,
      },
      autoplay: {
        delay: 4000,
        disableOnInteraction: false,
      },
      pagination: {
        el: pagination,
        clickable: true,
      },
      navigation: {
        prevEl: arrowPrev,
        nextEl: arrowNext,
      },
    });

    if (swiper && pagination) {
      hero.querySelector('.s-hero__pause').addEventListener('click', (e) => {
        if (e.currentTarget.classList.contains('s-hero__pause--active')) {
          e.currentTarget.classList.remove('s-hero__pause--active');
          swiper.autoplay.start();
        } else {
          e.currentTarget.classList.add('s-hero__pause--active');
          swiper.autoplay.stop();
        }
      });
    }
  }
};
export default blockHero;
