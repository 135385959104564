const shopCheckout = () => {
  const shopCheckoutcontactInputs = document.querySelectorAll(
    '.c-shop-checkout-contact__input',
  );

  const billingEmail = document.querySelector('.c-shop-field--billing-email');

  if (shopCheckoutcontactInputs.length > 0) {
    shopCheckoutcontactInputs.forEach((label) => {
      label.addEventListener('change', (e) => {
        if (e.currentTarget.value === 'login-user') {
          billingEmail.style.display = 'none';
          document
            .querySelector('[data-source="shop-checkout-login"]')
            .classList.add('c-popup--show');
          document.querySelector('.akson-body').classList.add('popap-open');
        } else {
          billingEmail.style.display = 'block';
        }
      });
    });
  }

  /**
   * Modyfikacje elementów modułu od adresów
   */
  const wcmcaAddressSelectorContainer = document.querySelector(
    '.wcmca_address_selector_container',
  );

  const wcmcaAddNewAddressButton = document.querySelector(
    '.wcmca_add_new_address_button',
  );

  if (wcmcaAddressSelectorContainer) {
    wcmcaAddressSelectorContainer.classList.add('c-shop-field');
    wcmcaAddressSelectorContainer
      .querySelector('label')
      .classList.add('c-shop-field__label');
  }
  if (wcmcaAddNewAddressButton) {
    wcmcaAddNewAddressButton.classList.add('c-btn');
  }
};

export default shopCheckout;
