const blockPartners = (Swiper) => {
  const partnersSliders = document.querySelectorAll('.s-partners__row');

  if (partnersSliders) {
    partnersSliders.forEach((element) => {
      // eslint-disable-next-line no-unused-vars
      const swiper = new Swiper(element, {
        direction: 'horizontal',
        slidesPerView: 2.3,
        spaceBetween: 15,
        loop: false,
        autoplay: {
          delay: 4000,
          disableOnInteraction: false,
        },
        breakpoints: {
          768: {
            slidesPerView: 4,
          },
          992: {
            slidesPerView: 5,
            spaceBetween: 32,
          },
        },
      });
    });
  }
};

export default blockPartners;
