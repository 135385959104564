import validationFieldPhone from './validate-field-phone';
import validationEmail from './validation-email';
import validationFieldPassword from './validation-field-password';
import validationFieldZip from './validation-field-zip';

const validationFields = (form) => {
  let count = 0;
  const requiredFields = form.querySelectorAll(
    '.cf-validation-required:not(.stop-requiring)',
  );
  if (requiredFields.length > 0) {
    requiredFields.forEach((field) => {
      const type = field.getAttribute('data-type');
      const input = field.querySelector('.cf-validation-input');
      const errorText = field.querySelector('.cf-validation-error');

      if (input) {
        if (type === 'email') {
          if (input.value === '' || !validationEmail(input.value)) {
            field.classList.add('cf-validation-required-fail');
            if (input.value === '') {
              errorText.innerHTML = 'To pole jest wymagane';
            } else if (!validationEmail(input.value)) {
              errorText.innerHTML = 'Niepoprawny adres e-mail';
            }
            count++;
          } else {
            field.classList.remove('cf-validation-required-fail');
          }
        } else if (type === 'text' || type === 'phone') {
          if (input.value === '') {
            field.classList.add('cf-validation-required-fail');
            errorText.innerHTML = 'To pole jest wymagane';
            count++;
          } else {
            field.classList.remove('cf-validation-required-fail');
          }
        } else if (type === 'zip') {
          count = validationFieldZip(input, field, count, errorText);
        } else if (type === 'tel') {
          count = validationFieldPhone(input, field, count, errorText);
        } else if (type === 'password') {
          count = validationFieldPassword(input, field, count, errorText);
        } else if (type === 'password-repeat') {
          const getPasswordField = document.querySelector('.check-password');
          if (getPasswordField) {
            if (input.value === '') {
              field.classList.add('cf-validation-required-fail');
              errorText.innerHTML = 'To pole jest wymagane';
              count++;
            } else if (input.value !== getPasswordField.value) {
              field.classList.add('cf-validation-required-fail');
              errorText.innerHTML = 'Hasła nie pasują do siebie';
              count++;
            } else if (input.value === getPasswordField.value) {
              field.classList.remove('cf-validation-required-fail');
            }
          }
        } else if (type === 'checkbox') {
          if (input.checked === false) {
            field.classList.add('cf-validation-required-fail');
            errorText.innerHTML = 'To pole jest wymagane';
            count++;
          } else {
            field.classList.remove('cf-validation-required-fail');
          }
        }
      }

      // remove error message if change value input
      field.addEventListener('input', () => {
        if (field.classList.contains('cf-validation-required-fail'))
          field.classList.remove('cf-validation-required-fail');
      });
    });
  }

  return count;
};

export default validationFields;
