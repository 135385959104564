const showSecondaryMenu = (primaryLevel) => {
  const primaryLevelId = primaryLevel.getAttribute('data-id');
  const primaryLevelName = primaryLevel.getAttribute('data-name');
  const backButton = document.querySelector('.c-header-mobile-menu__back');
  const primaryLevelItems = document.querySelectorAll('.primary-level');
  primaryLevelItems.forEach((el) => {
    if (el.getAttribute('data-id') !== primaryLevelId) {
      el.classList.add('primary-level--hidden');
    }
  });

  backButton.classList.remove('c-header-mobile-menu__back--hidden');
  backButton.querySelector('span').innerHTML = primaryLevelName;
};

const backFirstLevel = () => {
  const back = document.querySelector('.c-header-mobile-menu__back');
  const primaryLevelItems = document.querySelectorAll('.primary-level');
  const primaryBtns = document.querySelectorAll('.header-mobile-primary-btn');

  primaryBtns.forEach((el) => {
    el.closest('.c-header-mobile-menu__level').classList.remove(
      'c-header-mobile-menu__level--hidden',
    );
  });

  primaryLevelItems.forEach((el) => {
    el.classList.remove('primary-level--hidden');
    el.classList.remove('active-secondary');
  });

  back.classList.add('c-header-mobile-menu__back--hidden');
};

const headerMenu = (aksonBody) => {
  const toggleOpen = document.querySelector('.toggle-open');
  const toggleClose = document.querySelector('.toggle-close');

  if (toggleOpen) {
    toggleOpen.addEventListener('click', () => {
      aksonBody.classList.add('menu-show');
      document.body.style = 'overflow: hidden';
      document.querySelector('html').style = 'overflow: hidden; height: 100%';
    });
  }
  if (toggleClose) {
    toggleClose.addEventListener('click', () => {
      aksonBody.classList.remove('menu-show');
      backFirstLevel();
      document.body.style = 'overflow: visible';
      document.querySelector('html').style = 'overflow: visible; height: 100%';
    });
  }

  const mobileMenu = document.querySelector('.header-mobile-menu');
  const back = document.querySelector('.c-header-mobile-menu__back');

  if (mobileMenu) {
    const primaryBtns = mobileMenu.querySelectorAll(
      '.header-mobile-primary-btn',
    );

    primaryBtns.forEach((el) => {
      el.addEventListener('click', (e) => {
        e.currentTarget
          .closest('.c-header-mobile-menu__level')
          .classList.add('c-header-mobile-menu__level--hidden');
        showSecondaryMenu(e.currentTarget.closest('.primary-level'));
        e.currentTarget
          .closest('.c-header-mobile-menu__item--primary')
          .classList.add('active-secondary');
      });
    });
  }

  if (back) {
    back.addEventListener('click', () => {
      backFirstLevel();
    });
  }
};

export default headerMenu;
