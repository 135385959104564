import smoothScroll from './smooth-scroll';
import validationFields from './validation-fields';

const formSubmit = () => {
  let cfFormTimeout;
  const cfForm = document.querySelectorAll('.cf-form');
  if (cfForm.length > 0) {
    cfForm.forEach((form) => {
      form.addEventListener('submit', (e) => {
        e.preventDefault();
        clearTimeout(cfFormTimeout);
        cfFormTimeout = setTimeout(() => {
          /**
           * Walidacja pól
           */
          const countErrors = validationFields(form);
          if (countErrors === 0) {
            const formdata = new FormData(form);

            const ajax = new XMLHttpRequest();
            // eslint-disable-next-line no-undef
            ajax.open('POST', globalVariables.ajaxUrl, true);
            ajax.onreadystatechange = () => {
              if (ajax.readyState === 4 && ajax.status === 200) {
                if (ajax.responseText) {
                  const json = JSON.parse(ajax.responseText);
                  if (json.success) {
                    const cContactForm =
                      document.querySelector('.c-contact-form');
                    const formSuccess = form.parentNode.querySelector(
                      '.c-contact-form__success',
                    );
                    formSuccess.classList.add('c-contact-form__success--show');
                    if (cContactForm) {
                      smoothScroll(cContactForm.offsetTop, 1000);
                    }
                    form.remove();
                  } else {
                    console.log('Error contact form: ', json);
                  }
                }
              }
            };

            ajax.send(formdata);
          }
        }, 250);
      });
    });
  }
};

const contactForm = () => {
  /**
   * Akcje na input select
   */
  const selectInputs = document.querySelectorAll(
    '.c-contact-form__input--select',
  );
  if (selectInputs.length > 0) {
    selectInputs.forEach((select) => {
      select.addEventListener('change', (e) => {
        const closestField = e.currentTarget.closest(
          '.c-contact-form__field--select',
        );

        if (closestField) {
          closestField.classList.add('active-select');
        }
      });
    });
  }

  /**
   * Wysłanie formularza
   */

  formSubmit();

  /**
   * Schowanie pomyślnego popupu
   */
  const triggerCloseSuccess = document.querySelector('.trigger-close-success');
  if (triggerCloseSuccess) {
    triggerCloseSuccess.addEventListener('click', (e) => {
      e.preventDefault();
      const formSuccess = document.querySelector('.c-contact-form__success');
      formSuccess.classList.remove('c-contact-form__success--show');
    });
  }
};

export default contactForm;
