function containsSpecialChars(str) {
  return /[`!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/.test(str);
}

function containsUppercase(str) {
  return /[A-Z]/.test(str);
}

function containsLowercase(str) {
  return /[a-z]/.test(str);
}

const validationPassword = (password) => {
  const errors = [];
  if (password.length < 8) {
    errors.push('length');
  }
  if (!containsUppercase(password)) {
    errors.push('uppercase');
  }
  if (!containsLowercase(password)) {
    errors.push('lowercase');
  }
  if (!containsSpecialChars(password)) {
    errors.push('special');
  }

  return errors;
};

export default validationPassword;
