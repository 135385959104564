import { Loader } from '@googlemaps/js-api-loader';
import searchMarkers from './map-search-markers';
import mapSetMarkers from './map-set-markers';
import submitForm from './submit-form';

const shopPopupShipping = () => {
  if (document.querySelector('.woocommerce-checkout')) {
    /**
     * Zastosowanie adresu w metodzie
     */
    const shopPopupShippingBtn = document.querySelector(
      '.c-shop-popup-shipping__btn',
    );
    const shopPopupShippingInfo = document.querySelector(
      '.c-shop-popup-shipping__info',
    );
    console.log('shopPopupShippingInfo: ', shopPopupShippingInfo);
    if (shopPopupShippingBtn) {
      shopPopupShippingBtn.addEventListener('click', (e) => {
        console.log('shopPopupShippingBtn clicked');
        const shopLabelAddress = document.querySelector(
          '.c-shop-label__address',
        );
        console.log('shopLabelAddress: ', shopLabelAddress);
        const shippinhShopChoose = document.querySelector(
          '.shipping-shop-choose',
        );
        const billingShippingShopInput = document.querySelector(
          '#billing_shipping_shop',
        );

        const postId = e.currentTarget.getAttribute('data-post-id');
        console.log('postId: ', postId);

        const address = e.currentTarget.getAttribute('data-address');
        console.log('address: ', address);

        if (postId && address) {
          shopPopupShippingInfo.innerHTML = '';
          if (shopLabelAddress) {
            console.log('shopLabelAddress if: ', shopLabelAddress);

            const allMethod = document.querySelectorAll('#shipping_method input[name="shipping_method[0]"]');
            if(allMethod){
               allMethod.forEach(method => method.parentElement.classList.remove('shop-error'));
            }
            
            shopLabelAddress.innerHTML = address;
            // TODO: dodać tłumaczenie
            shippinhShopChoose.innerHTML = 'Zmień';
            billingShippingShopInput.value = postId;
            e.currentTarget
              .closest('.c-popup')
              .classList.remove('c-popup--show');
            document
              .querySelector('.akson-body')
              .classList.remove('popap-open');
          }
        } else {
          // TODO: dodać tłumaczenie
          shopPopupShippingInfo.innerHTML = 'Najpierw wybierz lokalizację!';
        }
      });
    }
  }
};
export default shopPopupShipping;
