import { MarkerClusterer } from '@googlemaps/markerclusterer';

const dispayVoivodeship = (postId) => {
  const sShopsSearchInside = document.querySelector('.s-shops-search__inside');
  const sShopsSearchSpecialists = document.querySelector(
    '.s-shops-search__specialists',
  );
  const sShopsSearchSpecialistsMobile = document.querySelector(
    '.s-shops-search__specialists-mobile',
  );

  const formdata = new FormData();
  formdata.append('action', 'display_voivodeships_click_action');
  // eslint-disable-next-line no-undef
  formdata.append('nonce', globalVariables.ajaxNonce);
  formdata.append('postId', postId);

  const ajax = new XMLHttpRequest();

  // eslint-disable-next-line no-undef
  ajax.open('POST', globalVariables.ajaxUrl, true);
  ajax.onreadystatechange = () => {
    if (ajax.readyState === 4 && ajax.status === 200) {
      if (ajax.responseText) {
        const json = JSON.parse(ajax.responseText);
        if (json.success) {
          const { voivodeshipsHtml } = json.data;

          if (voivodeshipsHtml) {
            sShopsSearchInside.style.display = 'none';
            if (window.matchMedia('(max-width: 768px)').matches) {
              sShopsSearchSpecialistsMobile.innerHTML = voivodeshipsHtml;
              sShopsSearchSpecialistsMobile.classList.add(
                's-shops-search__specialists-mobile--visible',
              );
            } else {
              sShopsSearchSpecialists.innerHTML = voivodeshipsHtml;
            }
          } else {
            sShopsSearchInside.style.display = 'block';
            if (window.matchMedia('(max-width: 768px)').matches) {
              sShopsSearchSpecialistsMobile.innerHTML = '';
              sShopsSearchSpecialistsMobile.classList.remove(
                's-shops-search__specialists-mobile--visible',
              );
            } else {
              sShopsSearchSpecialists.innerHTML = '';
            }
          }
        }
      }
    }
  };

  ajax.send(formdata);
};

// name
// rating
// reviews
// formattedAddress
// url
// openingHours
// internationalPhoneNumber

const mapSetMarkers = (map, formType = '') => {
  let selectedMarker;
  let selectedInfoWindow;
  const markers = [];
  // const mcOptions = {
  //   styles: [
  //     {
  //       // eslint-disable-next-line no-undef
  //       url: globalVariables.settings.clusters.first,
  //       width: 53,
  //       height: 53,
  //       textColor: '#fff',
  //     },
  //   ],
  // };

  if (
    // eslint-disable-next-line no-undef
    globalVariables.settings.coords &&
    // eslint-disable-next-line no-undef
    globalVariables.settings.coords.length > 0
  ) {
    // eslint-disable-next-line no-undef
    globalVariables.settings.coords.forEach((coord) => {
      // eslint-disable-next-line no-undef
      const marker = new google.maps.Marker({
        // eslint-disable-next-line no-undef
        position: new google.maps.LatLng(coord.lat, coord.lng),
        // eslint-disable-next-line no-undef
        icon: globalVariables.settings.markerPrimary,
        formattedAddress: coord.formattedAddress ? coord.formattedAddress : '',
        postId: coord.postId,
        map,
      });

      // eslint-disable-next-line no-undef
      const infoWindowContent = document.createElement('div');
      infoWindowContent.classList.add('c-info-window');

      let day = new Date().getDay();
      day -= 1;
      // nagłówek
      const headerElement = document.createElement('div');
      headerElement.classList.add('c-info-window__header');

      // nagłówek - lewo
      const leftElement = document.createElement('div');
      leftElement.classList.add('c-info-window__left');

      // nazwa
      const nameElement = document.createElement('div');
      nameElement.classList.add('c-info-window__name');
      nameElement.innerHTML = coord.name ? coord.name : '';
      leftElement.appendChild(nameElement);

      // opinie
      const ratingElement = document.createElement('div');
      ratingElement.classList.add('c-info-window__rating');

      const ratingElement1 = document.createElement('div');
      ratingElement1.innerHTML = coord.rating ? coord.rating : '';
      ratingElement.appendChild(ratingElement1);

      const ratingElement2 = document.createElement('div');
      // eslint-disable-next-line no-plusplus
      for (let index = 1; index < 6; index++) {
        ratingElement2.innerHTML += `<svg fill="${
          index <= coord.rating ? '#e8b425' : '#cfcfcf'
        }" baseProfile="tiny" height="24px" id="Layer_1" version="1.2" viewBox="0 0 24 24" width="24px" xml:space="preserve" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"><g><g><path d="M9.362,9.158c0,0-3.16,0.35-5.268,0.584c-0.19,0.023-0.358,0.15-0.421,0.343s0,0.394,0.14,0.521    c1.566,1.429,3.919,3.569,3.919,3.569c-0.002,0-0.646,3.113-1.074,5.19c-0.036,0.188,0.032,0.387,0.196,0.506    c0.163,0.119,0.373,0.121,0.538,0.028c1.844-1.048,4.606-2.624,4.606-2.624s2.763,1.576,4.604,2.625    c0.168,0.092,0.378,0.09,0.541-0.029c0.164-0.119,0.232-0.318,0.195-0.505c-0.428-2.078-1.071-5.191-1.071-5.191    s2.353-2.14,3.919-3.566c0.14-0.131,0.202-0.332,0.14-0.524s-0.23-0.319-0.42-0.341c-2.108-0.236-5.269-0.586-5.269-0.586    s-1.31-2.898-2.183-4.83c-0.082-0.173-0.254-0.294-0.456-0.294s-0.375,0.122-0.453,0.294C10.671,6.26,9.362,9.158,9.362,9.158z"/></g></g></svg>`;
      }
      ratingElement.appendChild(ratingElement2);

      const ratingElement3 = document.createElement('div');

      ratingElement3.innerHTML =
        coord.reviews && coord.reviews.length > 0
          ? `(${coord.reviews.length})`
          : '';

      ratingElement.appendChild(ratingElement3);

      // lewo
      leftElement.appendChild(ratingElement);

      headerElement.appendChild(leftElement);

      // nagłówek - prawo
      const rightElement = document.createElement('div');
      rightElement.classList.add('c-info-window__right');

      // kierunek
      const directionsElement = document.createElement('a');
      directionsElement.setAttribute('target', '_blank');
      directionsElement.setAttribute('href', coord.url ? coord.url : '#');
      directionsElement.classList.add('c-info-window__directions');
      directionsElement.innerHTML =
        '<svg viewBox="0 0 512 512" xmlns="http://www.w3.org/2000/svg"><path d="M502.61 233.32L278.68 9.39c-12.52-12.52-32.83-12.52-45.36 0L9.39 233.32c-12.52 12.53-12.52 32.83 0 45.36l223.93 223.93c12.52 12.53 32.83 12.53 45.36 0l223.93-223.93c12.52-12.53 12.52-32.83 0-45.36zm-100.98 12.56l-84.21 77.73c-5.12 4.73-13.43 1.1-13.43-5.88V264h-96v64c0 4.42-3.58 8-8 8h-32c-4.42 0-8-3.58-8-8v-80c0-17.67 14.33-32 32-32h112v-53.73c0-6.97 8.3-10.61 13.43-5.88l84.21 77.73c3.43 3.17 3.43 8.59 0 11.76z"/></svg>';
      rightElement.appendChild(directionsElement);

      headerElement.appendChild(rightElement);

      // middle
      const middleElement = document.createElement('div');
      middleElement.classList.add('c-info-window__middle');

      // kategoria
      const categoryElement = document.createElement('div');
      categoryElement.classList.add('c-info-window__category');
      categoryElement.innerHTML = 'Sprzęt medyczny'; // TODO: dodać tłumaczenie
      middleElement.appendChild(categoryElement);

      // adres
      const addressElement = document.createElement('div');
      addressElement.classList.add('c-info-window__address');
      addressElement.innerHTML = coord.formattedAddress
        ? coord.formattedAddress
        : '';
      middleElement.appendChild(addressElement);

      // stopka
      const footerElement = document.createElement('div');
      footerElement.classList.add('c-info-window__footer');

      // czy otwarte
      // const isOpenElement = document.createElement('div');
      // isOpenElement.classList.add('c-info-window__is-open');

      // // TODO: dodać tłumaczenie
      // isOpenElement.innerHTML =
      //   coord.openingHours && coord.openingHours.isOpen()
      //     ? '<span class="c-info-window__is-open">Otwarte</span>'
      //     : '<span class="c-info-window__is-open--close">Zamknięte</span>';

      // footerElement.appendChild(isOpenElement);

      // dzień
      const openingHours = coord.openingHours ? coord.openingHours[day] : '';
      if (openingHours) {
        const dayElement = document.createElement('div');
        dayElement.classList.add('c-info-window__day');
        dayElement.innerHTML = openingHours;
        footerElement.appendChild(dayElement);
      }

      // telefon
      const phoneNumber = coord.internationalPhoneNumber
        ? coord.internationalPhoneNumber
        : '';
      if (phoneNumber) {
        const phoneElement = document.createElement('a');
        phoneElement.classList.add('c-info-window__phone');
        phoneElement.setAttribute('href', `tel:${phoneNumber}`);
        phoneElement.innerHTML = phoneNumber;
        footerElement.appendChild(phoneElement);
      }

      infoWindowContent.appendChild(headerElement);
      infoWindowContent.appendChild(middleElement);
      infoWindowContent.appendChild(footerElement);

      //landingPage
      if (coord.landingPage) {
        const landingPageElement = document.createElement('a');
        landingPageElement.setAttribute('target', '_blank');
        landingPageElement.setAttribute('href', coord.landingPage);
        landingPageElement.classList.add('c-btn');
        landingPageElement.style.marginTop = '5px';
        landingPageElement.style.padding = '5px 10px';
        landingPageElement.style.fontWeight = '400';
        landingPageElement.style.fontSize = '1em';
        landingPageElement.style.lineHeight = '1.5em';
        landingPageElement.innerHTML = 'Przejdź do strony';
        infoWindowContent.appendChild(landingPageElement);
      }

      // eslint-disable-next-line no-undef
      const infoWindow = new google.maps.InfoWindow({
        content: infoWindowContent,
        map,
      });

      marker.info = infoWindow;
      // eslint-disable-next-line no-loop-func
      marker.addListener('click', () => {
        if (formType === 'shipping') {
          const shopPopupShippingBtn = document.querySelector(
            '.c-shop-popup-shipping__btn',
          );

          if (shopPopupShippingBtn) {
            const postId = marker.get('postId');

            const formattedAddress = marker.get('formattedAddress');

            shopPopupShippingBtn.setAttribute('data-post-id', postId);
            shopPopupShippingBtn.setAttribute('data-address', formattedAddress);
          }
        }

        if (selectedMarker) {
          // eslint-disable-next-line no-undef
          selectedMarker.setIcon(globalVariables.settings.markerPrimary);
        }
        selectedMarker = marker;

        if (selectedInfoWindow) {
          selectedInfoWindow.close();
        }
        selectedInfoWindow = infoWindow;
        // eslint-disable-next-line no-undef
        marker.setIcon(globalVariables.settings.markerSecondary);
        infoWindow.open({
          anchor: marker,
          map,
          shouldFocus: false,
        });

        if (formType !== 'shipping') {
          dispayVoivodeship(marker.get('postId'));
        }
      });

      markers.push(marker);
    });

    const icon = {
      // eslint-disable-next-line no-undef
      url: globalVariables.settings.clusters.first,
      width: 53,
      height: 53,
      textColor: '#fff',
      // eslint-disable-next-line no-undef
      scaledSize: new google.maps.Size(53, 53),
    };

    const renderer = {
      render({ count, position }) {
        // eslint-disable-next-line no-undef
        return new google.maps.Marker({
          label: { text: String(count), color: 'white', fontSize: '10px' },
          position,
          icon,
          // adjust zIndex to be above other markers
          // eslint-disable-next-line no-undef
          zIndex: Number(google.maps.Marker.MAX_ZINDEX) + count,
        });
      },
    };

    // eslint-disable-next-line no-undef, no-unused-vars
    const markerCluster = new MarkerClusterer({ map, markers, renderer });
    return markers;
  }
};

export default mapSetMarkers;
