const validationFieldZip = (input, field, count, errorText) => {
  if (input.value === '') {
    field.classList.add('cf-validation-required-fail');
    errorText.innerHTML = 'To pole jest wymagane';
    count++;
  } else if (input.value !== '' && !input.value.match(/^\d\d-\d\d\d$/)) {
    field.classList.add('cf-validation-required-fail');
    errorText.innerHTML = 'Kod pocztowy niepoprawny (00-000)';
    count++;
  } else {
    field.classList.remove('cf-validation-required-fail');
    errorText.innerHTML = '';
  }
  return count;
};
export default validationFieldZip;
