const mapRemoveFonts = () => {
  const head = document.getElementsByTagName('head')[0];

  if (head) {
    const { insertBefore } = head;

    head.insertBefore = (newElement, referenceElement) => {
      if (
        newElement.href &&
        newElement.href.indexOf(
          'https://fonts.googleapis.com/css?family=Roboto',
        ) === 0
      ) {
        return;
      }
      if (
        newElement.href &&
        newElement.href.indexOf(
          'https://fonts.googleapis.com/css?family=Google',
        ) === 0
      ) {
        return;
      }

      insertBefore.call(head, newElement, referenceElement);
    };
  }
};

export default mapRemoveFonts;
