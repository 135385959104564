const shopCart = () => {
  let timeout;

  const shopQuantity = document.querySelectorAll('.c-shop-quantity__input');

  if (shopQuantity.length > 0) {
    shopQuantity.forEach((element) => {
      element.addEventListener('change', () => {
        if (timeout !== undefined) {
          clearTimeout(timeout);
        }
        timeout = setTimeout(() => {
          const udateCart = document.querySelector('[name="update_cart"]');
          udateCart.removeAttribute('disabled');
          udateCart.setAttribute('aria-disabled', false);
          udateCart.click();
        }, 1000);
      });
    });
  }
};

export default shopCart;
