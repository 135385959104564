const allLinksSmooth = () => {
  document
    .querySelectorAll(
      'a[href^="#"]:not(.block-scroll):not(.s-sticky-list__toc-link)',
    )
    .forEach((anchor) => {
      anchor.addEventListener('click', (e) => {
        e.preventDefault();

        const href = anchor.getAttribute('href');
        const headerHtml = document.querySelector('.c-header');

        if (href) {
          const findElement = document.querySelector(href);
          if (findElement) {
            const currentPos = window.pageYOffset;
            let start = null;
            let pos = findElement.getBoundingClientRect().top;
            let additionalPosHeader = 48;

            if (window.matchMedia('(max-width: 768px)'))
              additionalPosHeader = 10;

            if (currentPos) pos += currentPos;

            if (headerHtml) {
              pos -= headerHtml.clientHeight + additionalPosHeader;
            }

            let time = 500;
            // eslint-disable-next-line no-unused-expressions, no-sequences
            (pos = +pos), (time = +time);
            window.requestAnimationFrame(function step(currentTime) {
              start = !start ? currentTime : start;
              const progress = currentTime - start;
              // eslint-disable-next-line no-undef
              if (currentPos < pos) {
                window.scrollTo(
                  0,
                  // eslint-disable-next-line no-undef
                  ((pos - currentPos) * progress) / time + currentPos,
                );
              } else {
                window.scrollTo(
                  0,
                  // eslint-disable-next-line no-undef
                  currentPos - ((currentPos - pos) * progress) / time,
                );
              }
              // eslint-disable-next-line no-undef
              if (progress < time) {
                window.requestAnimationFrame(step);
              } else {
                // eslint-disable-next-line no-undef
                window.scrollTo(0, pos);
              }
            });
          }
        }
      });
    });
};
export default allLinksSmooth;
