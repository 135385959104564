const mapSearchMarkers = (coords, map, mapMarkers = '') => {
  // const markers = [];

  // eslint-disable-next-line no-plusplus
  // for (let i = 0, len = coords.length; i < len; i++) {
  //   // eslint-disable-next-line no-undef, no-param-reassign
  //   const marker = new google.maps.Marker({
  //     // eslint-disable-next-line no-undef
  //     position: new google.maps.LatLng(coords[i].lat, coords[i].lng),
  //   });

  //   markers.push(marker);
  // }
  /**
   * Wyrównanie markerów do ekranu
   */
  for (let i = 0; i < mapMarkers.length; i++) {
    mapMarkers[i].info.close();
  }

  // eslint-disable-next-line no-undef
  const bounds = new google.maps.LatLngBounds();
  if (coords.length > 1) {
    coords.forEach((singleMarker) => {
      bounds.extend(
        // eslint-disable-next-line no-undef
        new google.maps.LatLng(singleMarker.lat, singleMarker.lng),
      );
    });
    map.fitBounds(bounds);

    for (let i = 0; i < mapMarkers.length; i++) {
      if (map.getBounds().contains(mapMarkers[i].getPosition())) {
        console.log(mapMarkers[i].getPosition());
        mapMarkers[i].info.open({
          anchor: mapMarkers[i],
          map,
        });
        break;
      }
    }
  } else if (coords.length === 1) {
    bounds.extend(
      // eslint-disable-next-line no-undef
      new google.maps.LatLng(coords[0].lat, coords[0].lng),
    );

    map.fitBounds(bounds);

    for (let i = 0; i < mapMarkers.length; i++) {
      if (map.getBounds().contains(mapMarkers[i].getPosition())) {
        mapMarkers[i].info.open({
          anchor: mapMarkers[i],
          map,
        });
      }
    }
  }
};
export default mapSearchMarkers;
