/*
  Project: Akson
  Author: Daniel Kozina
 */

import Swiper, { Navigation, Pagination, Autoplay } from 'swiper';
import './modules/slideToggle';

import headerMenu from './modules/header-menu';
import blockHero from './modules/block-hero';
import blockPosts from './modules/block-posts';
import blockPartners from './modules/block-partners';
import blockProductsList from './modules/block-products-list';
import blockPresentation from './modules/block-presentation';
import headerMiniCart from './modules/header-mini-cart';
import shopFilters from './modules/shop-filters';
import search from './modules/search';
import shopCart from './modules/shop-cart';
import jquery from './modules/jquery';
import cPopup from './modules/popup';
import blockShopsSearch from './modules/block-shops-search';
import shopPopupShipping from './modules/shop-popup-shipping';
import shopCheckout from './modules/shop-checkout';
import dropdown from './modules/dopdown';
import favorites from './modules/favorites';
import shopShowroom from './modules/shop-showroom';
import blockNewsletter from './modules/block-newsletter';
import configurator from './modules/configurator';
import info from './modules/info';
import blockContact from './modules/block-contact';
import customSelect from './modules/select';
import contactForm from './modules/contact-form';
import blockStickyList from './modules/block-sticky-list';
import blockFaq from './modules/block-faq';
import header from './modules/header';
import relatedProducts from './modules/related-products';
import shopFiltersMobile from './modules/shop-filters-mobile';
import headerFiltersMenu from './modules/header-filters-menu';
import allLinksSmooth from './modules/all-links-smooth';
import validationFormWoo from './modules/validation-form-woo';
import mapRemoveFonts from './modules/map-remove-fonts';
import liveChat from './modules/livechat';

document.addEventListener('DOMContentLoaded', () => {
  const aksonBody = document.querySelector('.akson-body');
  if (aksonBody) {
    headerMenu(aksonBody);
    headerFiltersMenu(aksonBody);
  }
  blockHero(Swiper, Navigation, Pagination, Autoplay);
  blockPosts(Swiper);
  blockPartners(Swiper);
  blockProductsList(Swiper, Navigation);
  blockPresentation();
  blockShopsSearch();
  header();
  headerMiniCart();
  shopFilters();
  shopFiltersMobile(aksonBody);
  search();
  shopCart();
  cPopup();
  shopPopupShipping();
  shopCheckout();
  dropdown();
  favorites();
  shopShowroom();
  blockNewsletter();
  configurator();
  info();
  blockContact();
  customSelect();
  contactForm();
  blockStickyList();
  blockFaq();
  relatedProducts(Swiper);
  allLinksSmooth();
  validationFormWoo();
  mapRemoveFonts();
  liveChat();
});

jquery();
