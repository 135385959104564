const closeAllInfos = (current) => {
  const infoBtns = document.querySelectorAll('.c-info');

  if (infoBtns.length > 0) {
    infoBtns.forEach((btn) => {
      if (btn !== current) {
        btn.classList.remove('c-info--show');
      }
    });
  }
};

const info = () => {
  /**
   * Open
   */
  const infoIcons = document.querySelectorAll('.c-info__icon');

  if (infoIcons.length > 0) {
    infoIcons.forEach((icon) => {
      icon.addEventListener('click', (e) => {
        e.preventDefault();
        const closestInfo = e.currentTarget.closest('.c-info');
        closeAllInfos(closestInfo);
        closestInfo.closest('.c-info').classList.toggle('c-info--show');
      });
    });
  }

  /**
   * Close
   */
  const infoCloses = document.querySelectorAll('.c-info__close');

  if (infoCloses.length > 0) {
    infoCloses.forEach((close) => {
      close.addEventListener('click', (e) => {
        e.preventDefault();
        const closestInfo = e.currentTarget.closest('.c-info');
        closestInfo.closest('.c-info').classList.remove('c-info--show');
      });
    });
  }
};
export default info;
