const blockNewsletter = () => {
  const newsletterSections = document.querySelectorAll('.s-newsletter');

  if (newsletterSections) {
    newsletterSections.forEach((newsletterSection) => {
      const formId = newsletterSection.getAttribute('data-form-id');
      if (formId) {
        document.addEventListener(
          'wpcf7mailsent',
          (event) => {
            if (parseInt(formId, 10) === event.detail.contactFormId) {
              const formHtml = newsletterSection.querySelector(
                '.s-newsletter__form',
              );
              const sentHtml = newsletterSection.querySelector(
                '.s-newsletter__sent',
              );
              if (formHtml && sentHtml) {
                formHtml.remove();
                sentHtml.classList.add('s-newsletter__sent--show');
              }
            }
          },
          false,
        );
      }
    });
  }
};

export default blockNewsletter;
