/**
 * Pokazywanie pozostałych kategorii
 */
// const showSearchCatsInit = () => {
//   const showSearchCats = document.querySelectorAll('.show-search-cats');

//   if (showSearchCats.length > 0) {
//     showSearchCats.forEach((el) => {
//       el.addEventListener('click', (e) => {
//         const clocestList = e.currentTarget.closest(
//           '.c-header-search-box__single--more',
//         );

//         if (clocestList) {
//           const headerSearchLoopCats = clocestList.querySelectorAll(
//             '.c-header-search-loop-cat',
//           );

//           if (headerSearchLoopCats.length > 0) {
//             // eslint-disable-next-line no-unused-vars
//             let listHeight = 0;
//             headerSearchLoopCats.forEach((cat, key) => {
//               if (key < 3) {
//                 listHeight += cat.offsetHeight;
//               }
//               cat.classList.remove('hidden');
//             });
//             clocestList.style.height = `${listHeight + 20}px`;
//             clocestList.style.overflow = 'auto';
//           }
//         }
//         e.currentTarget.remove();
//       });
//     });
//   }
// };

const search = () => {
  // let myTimeout;
  // const results = document.querySelector('.c-header-search-box');

  // const serchField = document.querySelector('.c-header-search');
  // const serchInput = document.querySelector('.c-header-search__input');
  // const searchForm = document.querySelector('.c-header__search-form');
  const alert = document.querySelector('.c-header-search__alert');

  if (alert) {
    alert.addEventListener('click', (e) => {
      // serchInput.value = '';
      // serchInput.dispatchEvent(new Event('input'));
      // serchField.classList.remove('c-header-search--active');
      if (window.matchMedia('(max-width: 768px)').matches) {
        e.currentTarget.closest('.c-header__col').classList.remove('show');
      }
    });
  }

  // serchInput.addEventListener('input', (e) => {
  //   if (e.currentTarget.value.length > 0) {
  //     alert.classList.remove('c-header-search__alert--hidden');
  //   } else {
  //     alert.classList.add('c-header-search__alert--hidden');
  //   }
  //   clearTimeout(myTimeout);
  //   myTimeout = setTimeout(() => {
  //     const formdata = new FormData(searchForm);
  //     const ajax = new XMLHttpRequest();
  //     alert.classList.add('c-header-search__alert--rotate');

  //     // eslint-disable-next-line no-undef
  //     ajax.open('POST', globalVariables.ajaxUrl, true);
  //     ajax.onreadystatechange = () => {
  //       if (ajax.readyState === 4 && ajax.status === 200) {
  //         if (ajax.responseText) {
  //           const json = JSON.parse(ajax.responseText);
  //           if (json.success) {
  //             serchField.classList.add('c-header-search--active');
  //             results.innerHTML = json.data.html;
  //             showSearchCatsInit();
  //           } else if (json.data) {
  //             serchField.classList.add('c-header-search--active');
  //             if (json.data.html) results.innerHTML = json.data.html;
  //           } else {
  //             serchField.classList.remove('c-header-search--active');
  //             results.innerHTML = '';
  //           }
  //           alert.classList.remove('c-header-search__alert--rotate');
  //         }
  //       }
  //     };

  //     ajax.send(formdata);
  //   }, 1000);
  // });
};

export default search;
