import validationPassword from './validation-password';

const validationFieldPassword = (input, field, count, errorText) => {
  const getvalidationFieldPasswordword = validationPassword(input.value);
  if (input.value === '') {
    field.classList.add('cf-validation-required-fail');
    errorText.innerHTML = 'To pole jest wymagane';
    count++;
  } else if (input.value !== '') {
    field.classList.add('cf-validation-required-fail');
    errorText.innerHTML = 'Hasło jest nie poprawne';

    const checkLength = document.querySelector('.check-length');
    const checkLowercase = document.querySelector('.check-lowercase');
    const checkUppercase = document.querySelector('.check-uppercase');
    const checkSpecial = document.querySelector('.check-special');

    if (!getvalidationFieldPasswordword.includes('length') && checkLength) {
      checkLength.classList.add('is-ok');
    } else {
      checkLength.classList.remove('is-ok');
      count++;
    }
    if (
      !getvalidationFieldPasswordword.includes('lowercase') &&
      checkLowercase
    ) {
      checkLowercase.classList.add('is-ok');
    } else {
      checkLowercase.classList.remove('is-ok');
      count++;
    }
    if (
      !getvalidationFieldPasswordword.includes('uppercase') &&
      checkUppercase
    ) {
      checkUppercase.classList.add('is-ok');
    } else {
      checkUppercase.classList.remove('is-ok');
      count++;
    }
    if (!getvalidationFieldPasswordword.includes('special') && checkSpecial) {
      checkSpecial.classList.add('is-ok');
    } else {
      checkSpecial.classList.remove('is-ok');
      count++;
    }

    if (getvalidationFieldPasswordword.length === 0) {
      field.classList.remove('cf-validation-required-fail');
    }
  }
  return count;
};

export default validationFieldPassword;
