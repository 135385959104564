const blockProductsList = (Swiper, Navigation) => {
  const sliders = document.querySelectorAll('.s-products-list__slider-init');

  if (sliders) {
    sliders.forEach((element) => {
      let mobileSlides = 5;
      let desktopSlides = 5;

      const dataSlidesMobile = element.getAttribute('data-slides-mobile');

      if (dataSlidesMobile) {
        mobileSlides = parseInt(dataSlidesMobile, 10);
      }

      const dataSlidesDesktop = element.getAttribute('data-slides-desktop');

      if (dataSlidesDesktop) {
        desktopSlides = parseInt(dataSlidesDesktop, 10);
      }

      const arrowPrev = element
        .closest('.s-products-list')
        .querySelector('.swiper-button-prev');
      const arrowNext = element
        .closest('.s-products-list')
        .querySelector('.swiper-button-next');

      // eslint-disable-next-line no-unused-vars
      const swiper = new Swiper(element, {
        direction: 'horizontal',
        modules: [Navigation],
        slidesPerView: 1.3,
        spaceBetween: 15,
        autoplay: {
          delay: 4000,
          disableOnInteraction: false,
        },
        navigation: {
          prevEl: arrowPrev,
          nextEl: arrowNext,
        },
        breakpoints: {
          992: {
            slidesPerView: desktopSlides,
          },
          768: {
            slidesPerView: mobileSlides,
          },
        },
      });
    });
  }
};
export default blockProductsList;
