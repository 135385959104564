const customSelect = () => {
  const newElement = function (element, attrs, content) {
    const el = document.createElement(element);
    // eslint-disable-next-line no-restricted-syntax, guard-for-in
    for (const key in attrs) {
      el.setAttribute(key, attrs[key]);
    }
    if (content) el.innerHTML = content;
    return el;
  };
  const selectors = document.querySelectorAll('select:not([name="rating"])');

  selectors.forEach((selector) => {
    selector.classList.add('c-custom-select--hide');

    let selectedOption = 'none';

    const createCsSelector = () => {
      const el = document.createElement('div');
      el.className = 'c-custom-select';
      el.setAttribute('data-selected', selectedOption || selector.value);
      el.setAttribute('data-active', 0);
      el.setAttribute('aria-label', 'select');
      return el;
    };
    const csSelectorDOM = createCsSelector();

    const createCsSelectedBox = () => {
      const iconContainer = newElement(
        'div',
        { class: 'c-custom-select-icon' },
        '<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M6 10L12 16L18 10" stroke="black" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/></svg>',
      );
      // eslint-disable-next-line no-shadow
      const selectedOption = newElement(
        'div',
        { class: 'cs-selected-option' },
        selector.options[selector.selectedIndex].text,
      );
      const wrapper = newElement('div', { class: 'cs-selected-box' });

      wrapper.appendChild(selectedOption);
      wrapper.appendChild(iconContainer);

      return wrapper;
    };
    const csSelectedBox = createCsSelectedBox();

    const createCsOptions = () => {
      const options = selector.querySelectorAll('option');
      const ul = newElement('ul', { class: 'cs-options-list' });
      options.forEach((option) => {
        const value = option.getAttribute('value');
        const text = option.innerText;

        const li = newElement(
          'li',
          { class: 'cs-options-list', 'data-value': value },
          text,
        );

        if (option.selected) selectedOption = value;
        if (option.classList.contains('c-custom-select-label'))
          li.className = 'c-custom-select-label';
        else li.className = 'cs-option';

        li.addEventListener('click', (e) => {
          e.stopPropagation();
          let parentDiv = csSelectedBox.closest('.c-contact-form__field');

          if (!parentDiv) {
            parentDiv = csSelectedBox.parentNode.parentNode;
          }
          if (parentDiv) {
            const htmlSelect = parentDiv.querySelector('select');
            if (htmlSelect) {
              const htmlSelectValueSearch = htmlSelect.querySelector(
                `[value='${value}']`,
              );
              if (htmlSelectValueSearch) {
                htmlSelectValueSearch.selected = true;
                const event = new Event('change', { bubbles: true });
                htmlSelect.dispatchEvent(event);
              }
            }
          }
          csSelectedBox.childNodes[0].innerHTML = text;
          csSelectorDOM.setAttribute('data-active', 0);
          csSelectorDOM.setAttribute('data-selected', value);
        });

        ul.appendChild(li);
      });

      return ul;
    };
    const csOptions = createCsOptions();

    csSelectorDOM.appendChild(csSelectedBox);
    csSelectorDOM.appendChild(csOptions);
    csSelectorDOM.addEventListener('click', (e) => {
      e.stopPropagation();

      // eslint-disable-next-line no-underscore-dangle
      const _self = csSelectorDOM;
      const active = _self.getAttribute('data-active');

      if (active == 0) _self.setAttribute('data-active', 1);
      else _self.setAttribute('data-active', 0);
    });

    document.addEventListener('click', (e) => {
      csSelectorDOM.setAttribute('data-active', 0);
    });

    selector.parentNode.insertBefore(csSelectorDOM, selector);
    // selector.remove();
  });
};

export default customSelect;
