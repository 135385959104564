const header = () => {
  const triggerShowSearch = document.querySelectorAll('.trigger-show-search');
  if (triggerShowSearch.length > 0) {
    triggerShowSearch.forEach((searchInitBtn) => {
      searchInitBtn.addEventListener('click', () => {
        const searchBar = document.querySelector('.c-header__col--search-bar');
        if (searchBar) {
          searchBar.classList.toggle('show');
        }
      });
    });
  }
};

export default header;
