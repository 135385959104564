/* eslint-disable */
const liveChat = () => {
  /**
   * Doładowanie widgetu
   */
  if (globalVariables.codesHeaderLiveChatTxt) {
    setTimeout(() => {
      eval(globalVariables.codesHeaderLiveChatTxt);

      /**
       * Zmiana pozycji gdy pasek od Showroom
       */

      const cShowroomSidebar = document.querySelector('.c-showroom-sidebar');
      if (cShowroomSidebar) {
        document.body.classList.add('showroom-sidebar-visible');
      }
    }, 4000);
  }
};
export default liveChat;
