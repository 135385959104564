import scrollSpy from 'simple-scrollspy';
import smoothScroll from './smooth-scroll';

const blockStickyList = () => {
  const header = document.querySelector('.c-header');

  const sectionTextAndToc = document.querySelector('.s-sticky-list');
  const navTextAndToc = document.querySelector('.s-sticky-list__toc');
  if (sectionTextAndToc) {
    window.addEventListener('scroll', () => {
      navTextAndToc.style.top = `${header.clientHeight + 48}px`;
      const scroll = window.scrollY;
      if (scroll > 0) {
        navTextAndToc.classList.add('s-sticky-list__toc--is-fixed');
      } else {
        navTextAndToc.classList.remove('s-sticky-list__toc--is-fixed');
      }
    });
  }

  if (document.querySelector('#side-menu')) {
    // eslint-disable-next-line no-unused-vars
    const options = {
      sectionClass: '.scrollspy',
      menuActiveTarget: '.s-sticky-list__toc-link',
      offset: header.clientHeight + 48,
      smoothScroll: true,
      smoothScrollBehavior: (element) => {
        smoothScroll(element.offsetTop, 500);
      },
    };
    scrollSpy('#side-menu', options);
  }
};

export default blockStickyList;
