const relatedProducts = (Swiper) => {
  const relatedProductsSlider = document.querySelectorAll(
    '.related-products-slider',
  );

  if (relatedProductsSlider) {
    relatedProductsSlider.forEach((element) => {
      // eslint-disable-next-line no-unused-vars
      const swiper = new Swiper(element, {
        direction: 'horizontal',
        slidesPerView: 1.3,
        spaceBetween: 15,
        breakpoints: {
          992: {
            slidesPerView: 5,
          },
          768: {
            slidesPerView: 3,
          },
        },
      });
    });
  }
};
export default relatedProducts;
