const smoothScroll = (pos, time) => {
  let headerHeight = 0;
  let stepsHeight = 0;
  const header = document.querySelector('.c-header');
  const steps = document.querySelector('.c-checkout-steps');

  if (header) {
    headerHeight = header.clientHeight;
  }
  if (steps) {
    stepsHeight = steps.clientHeight;
  }
  let newPos = pos - headerHeight - stepsHeight;

  const currentPos = window.pageYOffset;
  let start = null;
  // eslint-disable-next-line no-param-reassign
  if (time == null) time = 500;
  // eslint-disable-next-line no-unused-expressions, no-param-reassign, no-sequences
  (newPos = +newPos), (time = +time);
  window.requestAnimationFrame(function step(currentTime) {
    start = !start ? currentTime : start;
    const progress = currentTime - start;
    if (currentPos < newPos) {
      window.scrollTo(
        0,
        ((newPos - currentPos) * progress) / time + currentPos,
      );
    } else {
      window.scrollTo(
        0,
        currentPos - ((currentPos - newPos) * progress) / time,
      );
    }
    if (progress < time) {
      window.requestAnimationFrame(step);
    } else {
      window.scrollTo(0, newPos);
    }
  });
};

export default smoothScroll;
