const blockPosts = (Swiper) => {
  const postsSliders = document.querySelectorAll('.s-posts__row--mobile');

  if (postsSliders) {
    postsSliders.forEach((element) => {
      // eslint-disable-next-line no-unused-vars
      const swiper = new Swiper(element, {
        direction: 'horizontal',
        slidesPerView: 1.3,
        spaceBetween: 15,
      });
    });
  }
};

export default blockPosts;
