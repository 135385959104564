const favorites = () => {
  let myTimeout;
  const saveToLaters = document.querySelectorAll('.c-add-to-wishlist--add');
  if (saveToLaters) {
    saveToLaters.forEach((el) => {
      el.addEventListener('click', (e) => {
        e.preventDefault();
        el.classList.add('pulsation-stretch');
        const currentBtn = e.currentTarget;
        clearTimeout(myTimeout);
        myTimeout = setTimeout(() => {
          const formdata = new FormData(currentBtn.closest('form'));
          const ajax = new XMLHttpRequest();
          // eslint-disable-next-line no-undef
          ajax.open('POST', globalVariables.ajaxUrl, true);
          ajax.onreadystatechange = () => {
            if (ajax.readyState === 4 && ajax.status === 200) {
              if (ajax.responseText) {
                const json = JSON.parse(ajax.responseText);
                if (json.success) {
                  if (json.data.status === 'added') {
                    currentBtn.classList.add('added');
                  } else if (json.data.status === 'deleted') {
                    currentBtn.classList.remove('added');
                    if (currentBtn.classList.contains('favorites-trash')) {
                      currentBtn.closest('.c-shop-account-order-item').remove();
                      const countItems = document.querySelectorAll(
                        '.c-shop-account-order-item',
                      );
                      if (countItems.length === 0) {
                        document
                          .querySelector('.l-shop-my-account__empty')
                          .classList.remove('l-shop-my-account__empty--hidden');
                      }
                    }
                  }
                }
                el.classList.remove('pulsation-stretch');
              }
            }
          };

          ajax.send(formdata);
        }, 500);
      });
    });
  }
};

export default favorites;
